import { EXPANDABLE_MIN_HEIGHT, PRINCIPAL_MAIN_HEADER_HEIGHT, isTalosUser, useDynamicCallback } from '@talos/kyoko';
import { isEqual } from 'lodash';
import {
  Children,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  type MutableRefObject,
  type PropsWithChildren,
} from 'react';
import { useTheme } from 'styled-components';
import { useUser } from '../../hooks';
import { DragHandle, Panel, Wrapper } from './styles';

export { ExpandablePanelHeader } from './ExpandablePanelHeader';

/** @deprecated Use Kyoko version instead */
export const ExpandableBottomPanel = memo(
  ({
    height,
    isDragged,
    isMaximized,
    isMinimized,
    onDragStarted,
    onAdjustedHeight,
    alwaysDrawContent = true,
    children,
  }: PropsWithChildren<{
    height: number;
    isDragged: MutableRefObject<boolean>;
    isMaximized?: boolean;
    isMinimized?: boolean;
    onDragStarted?(): void;
    onAdjustedHeight: (height: number) => any;
    alwaysDrawContent?: boolean;
  }>) => {
    const dragHandleRef = useRef<HTMLDivElement>(null);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const { spacingSmall } = useTheme();
    const [currHeight, setCurrHeight] = useState(height);
    const [headerChild, contentChild] = Children.toArray(children);

    const user = useUser();
    const showAdminHeader = user.IsMorphed || isTalosUser(user);

    const shouldDrawContent = useMemo(() => {
      // currHeight tracks the height while user is resizing/dragging the panel whereas height is the state passed from
      // parent component setting the initial height. Until the blotter-toggle on the header was added, the only way to increase blotter height
      // was either click the expand button or dragging, however the header toggle is now able to override the height too
      return alwaysDrawContent || isMaximized || currHeight > EXPANDABLE_MIN_HEIGHT || height > EXPANDABLE_MIN_HEIGHT;
    }, [alwaysDrawContent, currHeight, isMaximized, height]);

    const getHeight = useCallback(
      (position: number) => {
        return Math.max(
          EXPANDABLE_MIN_HEIGHT,
          Math.min(window.innerHeight - PRINCIPAL_MAIN_HEADER_HEIGHT + spacingSmall / 2, window.innerHeight - position)
        );
      },
      [spacingSmall]
    );

    const dragIt = useDynamicCallback(() => {
      const dragHandler = e => {
        if (isDragged.current) {
          const calculatedHeight = getHeight(e.y);
          setCurrHeight(calculatedHeight);
          if (wrapperRef.current) {
            wrapperRef.current.style.transition = 'none';
            wrapperRef.current.style.height = `${calculatedHeight}px`;
          }
        }
      };
      const dropHandler = e => {
        if (isDragged.current) {
          isDragged.current = false;
          onAdjustedHeight(getHeight(e.y));
          if (wrapperRef.current) {
            wrapperRef.current.style.height = '';
            wrapperRef.current.style.transition = 'height 300ms ease';
          }
        }
        document.removeEventListener('mousemove', dragHandler);
        document.removeEventListener('mouseup', dropHandler);
      };
      dragHandleRef?.current?.addEventListener('mousedown', () => {
        isDragged.current = true;
        onDragStarted?.();
        document.addEventListener('mousemove', dragHandler);
        document.addEventListener('mouseup', dropHandler);
      });
    });

    useEffect(() => {
      if (dragHandleRef.current) {
        dragIt();
      }
    }, [getHeight, isDragged, dragIt]);

    return (
      <Wrapper height={height} ref={wrapperRef}>
        <Panel height={height} isContentExpanded={isMaximized} showAdminHeader={showAdminHeader}>
          <DragHandle
            className={`${isDragged.current ? 'active' : ''} ${isMaximized ? 'hidden' : ''}`}
            ref={dragHandleRef}
          />
          {headerChild}
          {shouldDrawContent && contentChild}
        </Panel>
      </Wrapper>
    );
  },
  (p, n) => isEqual(p, n)
);
