import styled, { css } from 'styled-components';
import { setAlpha } from '../../styles';
import { Box } from '../Core';

import { Icon } from '../Icons/Icon';

export const AlertWrapper = styled(Box)<{ isBordered: boolean }>`
  display: flex;
  justify-content: flex-start;
  flex: 1;

  ${({ isBordered, theme }) =>
    isBordered &&
    css`
      border-radius: 3px;
      border: solid 1px ${theme.colors.gray['050']};
    `}

  ${({ theme, p }) => css`
    background: ${setAlpha(0.04, theme.colors.gray['100'])};
    border: solid 1px ${theme.colors.gray['050']};
    color: ${theme.colorTextImportant};
  `}

  > ${Icon}:last-child {
    cursor: pointer;
  }
`;

AlertWrapper.defaultProps = {
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  flex: 1,
  gap: 'spacingDefault',
  p: 'spacingMedium',
  fontSize: 'fontSizeDefault',
};
