import { useCallback, useEffect, useMemo, useState } from 'react';
import 'styled-components';

import { useMouseHover } from '../../hooks';
import { withAccordionGroup } from '../Accordion';
import { SidebarContext } from './SidebarContext';
import { SideBarChildrenWrapper, SidebarContent, SidebarWrapper } from './styles';
import type { SidebarProps } from './types';

export * from './SidebarContext';
export * from './SidebarGroup';
export * from './SidebarHeader';
export * from './SidebarItem';
export * from './SidebarPinToggle';
export * from './SidebarThemeToggle';
export * from './SidebarTitle';
export { ENV_COLORS, SidebarSection } from './styles';
export * from './types';

const TIMEOUT_DURATION = 200;

export const Sidebar = withAccordionGroup(function Sidebar({
  children,
  initialPinned = false,
  expandedWidth = 'auto',
  ...props
}: SidebarProps) {
  const [isPinned, setPinned] = useState<boolean>(initialPinned);
  const [isExpanded, setExpanded] = useState<boolean>(initialPinned);
  const [showSubNavItems, setShowSubNavItems] = useState<boolean>(initialPinned);

  const onMouseEnter = useCallback(() => {
    setExpanded(true);
  }, []);
  const onMouseLeave = useCallback(() => {
    if (!isPinned) {
      setExpanded(false);
    }
  }, [isPinned]);
  const hoverProps = useMouseHover({ onMouseEnter, onMouseLeave, delay: { onMouseEnter: TIMEOUT_DURATION } });

  useEffect(() => {
    setExpanded(isPinned);
  }, [isPinned]);

  const value = useMemo(
    () => ({
      isExpanded,
      isPinned,
      showSubNavItems,
      setExpanded,
      setPinned,
      setShowSubNavItems,
    }),
    [isExpanded, isPinned, showSubNavItems]
  );

  return (
    <SidebarContext.Provider value={value}>
      <SidebarWrapper
        data-testid="sidebar"
        isPinned={isPinned}
        expandedWidth={expandedWidth}
        {...props}
        onMouseLeave={e => hoverProps.onMouseLeave?.(e)}
      >
        <SidebarContent isExpanded={isExpanded} isPinned={isPinned} expandedWidth={expandedWidth}>
          <SideBarChildrenWrapper onMouseEnter={e => hoverProps.onMouseEnter?.(e)}>{children}</SideBarChildrenWrapper>
        </SidebarContent>
      </SidebarWrapper>
    </SidebarContext.Provider>
  );
});
