import { abbreviate } from '../../utils';
import { HStack } from '../Core';
import { Text } from '../Text';

export function AbbrLimit({ value, currency }: { value?: string; currency?: string }) {
  return (
    <HStack gap="spacingSmall">
      <Text>{abbreviate(value)}</Text>
      {currency && <Text color="colorTextSubtle">{currency}</Text>}
    </HStack>
  );
}
