import type { StackedChartDataItem } from './InnerStackedChart';

// HACK: highcharts stacked charts starts showing a very thin stack when the data is over 48 entries long
// filter the minimum positive and minimum negative values, whichever is smaller, until the data is under 48 entries
export function filterStackedChartDisplaySeries(data: StackedChartDataItem[]) {
  if (data.length <= 48) {
    return data;
  }
  const filteredData = data.filter(val => val.value !== 0).map(item => item.value);
  const sortableNegativeFromSmallToLarge = filteredData.filter(val => val < 0).sort((a, b) => a - b);
  const sortablePositiveFromLargeToSmall = filteredData.filter(val => val > 0).sort((a, b) => b - a);
  const arrayToEval = sortableNegativeFromSmallToLarge.concat(sortablePositiveFromLargeToSmall);
  // get min and max to slice on
  let min = 0;
  let max = arrayToEval.length - 1;
  while (max - min >= 48) {
    if (arrayToEval[min] < Math.abs(arrayToEval[max])) {
      min++;
    } else {
      max--;
    }
  }
  return arrayToEval.slice(min, max + 1);
}
