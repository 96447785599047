import type {
  EditableCallbackParams,
  ICellEditorParams,
  ICellRendererParams,
  ITooltipParams,
  SuppressKeyboardEventParams,
  ValueFormatterParams,
  ValueGetterParams,
} from 'ag-grid-community';
import { get, isNumber } from 'lodash';
import type { ReactNode } from 'react';
import { defineMessages } from 'react-intl';
import type { MarketAccount } from '../../../contexts';
import { Transfer } from '../../../types';
import type { CustomerMarketAccount } from '../../../types/CustomerMarketAccount';
import { MarketAccountTypeEnum, MarketTypeEnum, TradeSourceEnum } from '../../../types/types';
import { AgGridDefaultHeaderTooltip } from '../../AgGrid';
import type { AgGridSearchSelectDropdownProps } from '../../AgGrid/types';
import { Flex } from '../../Core';
import { Icon, IconName } from '../../Icons';
import { FormattedMessage } from '../../Intl';
import { Text } from '../../Text';
import { equalityAggFunc } from '../aggFuncs';
import { baseColumn } from './baseColumn';
import type { ColDefFactory, Column } from './types';

const messages = defineMessages({
  tradeBookedManually: {
    defaultMessage: 'This trade was booked manually.',
    id: 'BlotterTable.columns.tradeBookedManually',
  },
  account: {
    defaultMessage: 'Account',
    id: 'BlotterTable.columns.account',
  },
  marketAccount: {
    defaultMessage: 'Market Account',
    id: 'BlotterTable.columns.marketAccount',
  },
  customerAccountID: {
    defaultMessage: 'Customer Account ID',
    id: 'BlotterTable.columns.customerAccountID',
  },
  sourceTransferAccount: {
    defaultMessage: 'Source Account',
    id: 'BlotterTable.columns.sourceTransferAccount',
  },
  destinationTransferAccount: {
    defaultMessage: 'Destination Account',
    id: 'BlotterTable.columns.destinationTransferAccount',
  },
});

type MarketAccountValue = string | undefined;

export interface TransferMarketAccountColumnParams extends MarketAccountColumnParams {
  sourceOrDestination: 'source' | 'destination';
}

export interface MarketAccountColumnParams {
  /** An optional lookup map to use. If not provided, defaults to the context's equivalent lookup map. */
  marketDisplayNameByName?: Map<string, string>;
  /** An optional lookup map to use for principal. If not provided, defaults to the context's equivalent lookup map. */
  marketAccountsByName?: Map<string, MarketAccount>;
  /** An optional lookup map to use for whitelabel. If not provided, defaults to the context's equivalent lookup map. */
  customerMarketAccountsBySourceAccountID?: Map<string, CustomerMarketAccount>;
  /** An optional color which will be forwarded to the Text component.  */
  color?: string;
  /** Used for cell editing, setting this to true mandates that a "Counterparty" field is filled before the MarketAccount cell. */
  requiresCounterparty?: boolean;
  /** Used for cell editing. Emulates the Autocomplete getDescription, but also sends back the current selection (value) as well as the marketAccountName the description should be for.  */
  getDescription?: (marketAccountName: string, value: string | undefined) => string;
  /** Used for cell editing. Emulates the Autocomplete getIsDisabled, but also sends back the current selection (value) as well as the marketAccountName the disabled state should be for.  */
  getIsDisabled?: (item: string, value: string | undefined) => boolean;
  /** Whether or not to prefix MarketAccounts at a Market.Type === Custodian Market with the market display name */
  prefixCustodianMarketAccounts?: boolean;
  /** Whether or not to prefix customer market accounts with their Counterparty */
  prefixCustomerMarketAccountsWithCpty?: boolean;
}

/** Source/Destination Market Account columns for the transfers blotter  */
export const marketAccountTransfer: ColDefFactory<Column<TransferMarketAccountColumnParams>> = column => {
  const sourceOrDest = column.params?.sourceOrDestination;
  return {
    ...marketAccount({
      ...column,
      title: column.title ?? {
        intlDescriptor:
          sourceOrDest === 'source' ? messages.sourceTransferAccount : messages.destinationTransferAccount,
      },
    }),
    valueFormatter: (params: ValueFormatterParams<Transfer, MarketAccountValue>) => {
      const transfer = params.node?.data;
      if (!sourceOrDest || !transfer || !(transfer instanceof Transfer)) {
        return '(Invalid)';
      }

      return transfer.getAccountDisplay(params.context.current.marketAccountsByID, sourceOrDest);
    },
  };
};

// Same as marketAccount, but header value is "Account"
export const account: ColDefFactory<Column<MarketAccountColumnParams>> = column => {
  return marketAccount({ ...column, title: column.title ?? { intlDescriptor: messages.account } } satisfies Column);
};

export const marketAccount: ColDefFactory<Column<MarketAccountColumnParams>> = column => ({
  ...baseColumn({ ...column, title: column.title ?? { intlDescriptor: messages.marketAccount } }, true),
  valueFormatter: ({ value, context }: ValueFormatterParams<unknown, MarketAccountValue>) => {
    if (value == null) {
      return '';
    }

    const marketAccountsByName =
      column?.params?.marketAccountsByName ?? context.current.marketAccountsByName ?? new Map<string, MarketAccount>();
    const customerMarketAccountsBySourceAccountID =
      column?.params?.customerMarketAccountsBySourceAccountID ??
      context.current.customerMarketAccountsBySourceAccountID ??
      new Map<string, CustomerMarketAccount>();
    const { showMarketAccountDisplayName } = context.current.wlOrgConfig ?? {};

    const marketAccount = marketAccountsByName.get(value);
    const customerMarketAccount = customerMarketAccountsBySourceAccountID.get(value);
    const marketAccountDisplayName: string =
      marketAccount?.DisplayName ||
      (showMarketAccountDisplayName === true && customerMarketAccount?.DisplayName) ||
      value;

    const marketTypeByName = context.current.marketTypeByName ?? new Map<string, MarketTypeEnum>();
    const marketType = marketAccount ? marketTypeByName.get(marketAccount.Market) : undefined;
    const marketDisplayNameByName =
      column?.params?.marketDisplayNameByName ?? context.current.marketDisplayNameByName ?? new Map<string, string>();
    const marketDisplayName = marketAccount ? marketDisplayNameByName.get(marketAccount.Market) : undefined;

    const prefix = column.params?.prefixCustodianMarketAccounts;
    if (prefix && marketDisplayName != null && marketType === MarketTypeEnum.Custodian) {
      return `${marketDisplayName} - ${marketAccountDisplayName}`;
    }

    if (marketAccount?.Type === MarketAccountTypeEnum.Customer) {
      // For customer market accounts, the SourceAccountID is the effective display name.
      // Fallbacking to marketDisplayName doesn't make sense in this case since that's just "dealer". Instead,
      // we fallback to the unformatted value (market account name)

      const customerName: string | undefined = marketAccount.Counterparty;
      const customer = customerName ? context.current.customersByName?.get(customerName) : undefined;
      if (customer != null && column.params?.prefixCustomerMarketAccountsWithCpty) {
        // If we're able to resolve a customer, then use that. Else we're gonna fall back to just value below.
        return `${customer.DisplayName ?? customer.Name} - ${marketAccount.SourceAccountID ?? value}`;
      }

      return marketAccount.SourceAccountID ?? value;
    }

    return marketAccountDisplayName ?? marketDisplayName ?? '';
  },
  cellRenderer: ({ data, context, valueFormatted }: ICellRendererParams<unknown, string>) => {
    const tradeSource: string | undefined = get(data, 'TradeSource');
    return (
      <Flex justifyContent="space-between" gap="spacingSmall" theme={context.current.theme}>
        <Text color={column?.params?.color}>{valueFormatted}</Text>
        {tradeSource === TradeSourceEnum.User && <Icon theme={context.current.theme} icon={IconName.Bookmark} />}
      </Flex>
    );
  },
  tooltipValueGetter: ({ data }: ITooltipParams<unknown, MarketAccountValue>): TradeSourceEnum | undefined => {
    return get(data, 'TradeSource');
  },
  tooltipComponent: MarketAccountTooltipComponent,
  cellEditor: 'searchSelectDropdown',
  cellEditorPopup: true,
  suppressKeyboardEvent: (params: SuppressKeyboardEventParams) => params.event.key === 'Enter',
  // Param requiresCounterparty mandates that the Counterparty field is filled before the MarketAccount field
  cellEditorParams: (params: ICellEditorParams<unknown, MarketAccountValue>) => {
    const { data, context, value } = params;
    const marketAccountsByName =
      column?.params?.marketAccountsByName ?? context.current.marketAccountsByName ?? new Map<string, MarketAccount>();
    const marketDisplayNameByName =
      column?.params?.marketDisplayNameByName ?? context.current.marketDisplayNameByName ?? new Map<string, string>();

    const counterparty: string | undefined = get(data, 'Counterparty');
    const items = [...marketAccountsByName.values()]
      .filter(ma => (column?.params?.requiresCounterparty ? ma.Counterparty === counterparty : true))
      .map(marketAccount => marketAccount.Name)
      .sort();

    return {
      ...params,
      useSearchSelectParams: {
        items,
        getLabel: (marketAccountName: string) =>
          marketAccountsByName.get(marketAccountName)?.DisplayName ?? marketAccountName,
        getDescription: (marketAccountName: string) => {
          return (
            column?.params?.getDescription?.(marketAccountName, value) ||
            (marketDisplayNameByName.get(marketAccountsByName.get(marketAccountName)?.Market || '') ??
              marketAccountName)
          );
        },
        isItemDisabled: item => column?.params?.getIsDisabled?.(item, value) || false,
      },
    } satisfies AgGridSearchSelectDropdownProps<string>;
  },
  editable: (params: EditableCallbackParams<unknown>) => {
    const counterparty: string | undefined = get(params.data, 'Counterparty');
    if (column?.params?.requiresCounterparty && !counterparty) {
      return false;
    }
    // column.editable can be a boolean or a callback
    if (typeof column?.editable === 'function') {
      return column.editable(params);
    }
    return Boolean(column.editable);
  },

  valueGetter: (params: ValueGetterParams<unknown>) => {
    return getMarketAccountValue(column, params);
  },
  /**
   * We define both a valueGetter and a filterValue getter.
   * The distinction is that the filterValueGetter needs to understand how to resolve values from grouped rows (where node.data doesn't exist)
   * However, we do not want that ability in the normal valueGetter
   *
   * In an ideal world this distinction isn't necessary. Future work: improve the group column to allow us to not involve any grouping logic
   * into individual column factories such as this one.
   */
  filterValueGetter: (params: ValueGetterParams<unknown>) => {
    if (params.node?.group) {
      return params.node?.key;
    }

    return getMarketAccountValue(column, params);
  },
  aggFunc: column.aggregate ? equalityAggFunc : undefined,
});

const MarketAccountTooltipComponent = (params: ITooltipParams<unknown, TradeSourceEnum>): ReactNode => {
  if (params.location === 'header') {
    return <AgGridDefaultHeaderTooltip params={params} />;
  }
  const { value: tradeSource } = params;
  return tradeSource === TradeSourceEnum.User ? (
    <div className="ag-tooltip ag-popup-child" style={{ whiteSpace: 'nowrap' }}>
      <FormattedMessage {...messages.tradeBookedManually} />
    </div>
  ) : null;
};

function getMarketAccountValue(
  column: Column<MarketAccountColumnParams>,
  { node, context }: ValueGetterParams<unknown>
) {
  if (!node?.data) {
    return undefined;
  }
  // If its a number, we're pointed at a MarketAccountID. Resolve into a MarketAccount(Name).
  const value: number | string | undefined = column.field ? get(node.data, column.field) : undefined;
  if (isNumber(value)) {
    return context.current.marketAccountsByID?.get(value)?.Name;
  }

  return value;
}

// This is identical to the Market Account column, except it displays the SourceAccountID
// of the MarketAccount instead of the DisplayName
/**
 * @deprecated this column factory should avoid being used, it should be phased out and built into marketAccount directly above
 */
export const marketAccountSourceAccountID: ColDefFactory<Column<MarketAccountColumnParams>> = column => ({
  ...marketAccount({ ...column, title: column.title ?? { intlDescriptor: messages.customerAccountID } }),
  valueFormatter: ({ value, context }: ValueFormatterParams<unknown, string>) => {
    const marketAccountsByName =
      column?.params?.marketAccountsByName ?? context.current.marketAccountsByName ?? new Map<string, MarketAccount>();
    const marketDisplayNameByName =
      column?.params?.marketDisplayNameByName ?? context.current.marketDisplayNameByName ?? new Map<string, string>();
    // Use SourceAccountID here
    const marketAccount = marketAccountsByName.get(value);
    const sourceAccountID = marketAccount?.SourceAccountID ?? value;
    const marketDisplayName = marketAccount ? marketDisplayNameByName.get(marketAccount.Market) : undefined;
    return sourceAccountID ?? marketDisplayName ?? '';
  },
  // TODO -- this is a bit sketchy. The value of this column will be a MarketAccount.Name, but the filterValueGetter is returning the MarketAccount.SourceAccountID.
  // Before removing this column factory, we need to weed out where we're relying on this and (probably) create a new SourceAccountID column factory to replace it...
  filterValueGetter: ({ data, context }) => {
    const marketAccountsByName =
      column?.params?.marketAccountsByName ?? context.current.marketAccountsByName ?? new Map<string, MarketAccount>();
    const marketAccount = marketAccountsByName.get(data.MarketAccount);
    return marketAccount?.SourceAccountID;
  },
});
