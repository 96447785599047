import { Flex } from '@talos/kyoko';
import type { PropsWithChildren } from 'react';

export const EmptyPanel = ({ children }: PropsWithChildren) => {
  return (
    <Flex alignItems="center" justifyContent="center" w="100%" h="100%" p="spacingSmall">
      <Flex alignItems="center" justifyContent="center" w="100%" h="100%" background="gray.020">
        {children}
      </Flex>
    </Flex>
  );
};
