import type { BlotterTableFilter, FilterableProperty, UseFilterBuilderProps } from '@talos/kyoko';
import { usePortfolioViewStateSelector } from 'containers/Portfolio/PortfolioManagement/stateManagement/portfolioViewLayoutSlice.hooks';
import { useSubAccountRelationMap } from 'hooks/useSubAccountRelationMap';
import { useSubAccounts } from 'providers/SubAccountsContext';
import { createContext, useContext, useMemo, type PropsWithChildren } from 'react';

/** The key to pass into the blotter filter function, which will change based on whatever is directing the Context Blotter Filter ReactContext
 */
export type ContextBlotterFilterState = {
  SubAccounts?: string[];
  MarketAccounts?: string[];
};

export type ContextBlotterFilterMergeArg = {
  initialFilter?: ContextBlotterFilterState | undefined;
  filterBuilderProps?: Partial<UseFilterBuilderProps>;
  filterableProperties?: FilterableProperty[];
  changeFilter?: (action: React.SetStateAction<BlotterTableFilter>) => void;
  setFilter?: (filter: BlotterTableFilter) => void;
};

type ContextBlotterFilterValue = {
  /** The current filtering state for the filters in this context
   * - if there is no filtering to be applied, filter is set to undefined, but we want the
   * object to always exist so we can test for its presence
   */
  filter:
    | {
        key: keyof ContextBlotterFilterState;
        state: ContextBlotterFilterState;
      }
    | undefined;
};

const ContextBlotterFilterContext = createContext<ContextBlotterFilterValue | undefined>(undefined);

/** exported for Mocking purposes only */
export const TESTONLYContextBlotterFilterContext = ContextBlotterFilterContext;

/** External Blotter Filter Context to (optionally) provide a filtering context to blotters within it it
 * - this initial implementation is related to the Portfolio Management System-related Redux selectors, but
 * it could be extended to other Redux selector contexts in the futures
 * - if no filterKey is passed, no special filtering will be applied
 */
export const ContextBlotterFilterProvider = ({
  children,
  filterKey,
}: PropsWithChildren<{ filterKey?: keyof ContextBlotterFilterState }>) => {
  const { subAccountsByID } = useSubAccounts();
  const subAccountRelationMap = useSubAccountRelationMap();
  const { selectedPortfolioId, selectedMarketAccountId } = usePortfolioViewStateSelector();

  // Build the subAccountList based on the subAccount and it's children
  const subAccountList = useMemo(() => {
    if (filterKey !== 'SubAccounts') {
      return undefined;
    }
    if (!selectedPortfolioId || !subAccountRelationMap.get(selectedPortfolioId)) {
      return undefined;
    }
    const subAccountRelations = subAccountRelationMap.get(selectedPortfolioId);
    const subAccountIds = [selectedPortfolioId, ...(subAccountRelations?.children ?? [])];
    return subAccountIds.map(id => subAccountsByID.get(id)?.Name ?? '');
  }, [filterKey, selectedPortfolioId, subAccountRelationMap, subAccountsByID]);

  const additionalFilterState = useMemo(() => {
    if (!filterKey) {
      return undefined;
    }
    switch (filterKey) {
      case 'SubAccounts':
        return {
          SubAccounts: subAccountList,
        };
      case 'MarketAccounts':
        return {
          MarketAccounts: selectedMarketAccountId ? [selectedMarketAccountId] : undefined,
        };
    }
  }, [filterKey, selectedMarketAccountId, subAccountList]);

  const contextValue = useMemo(() => {
    if (!filterKey || !additionalFilterState) {
      return undefined;
    }
    return {
      key: filterKey,
      state: additionalFilterState,
    };
  }, [additionalFilterState, filterKey]);

  return (
    <ContextBlotterFilterContext.Provider
      value={{
        filter: contextValue,
      }}
    >
      {children}
    </ContextBlotterFilterContext.Provider>
  );
};

export function useContextBlotterFilterProvider() {
  const context = useContext(ContextBlotterFilterContext);
  if (!context) {
    throw new Error('useContextBlotterFilterProvider must be used within a ContextBlotterFilterProvider');
  }
  return context;
}

/** Hook providing state and management functions for the {@see ContextBlotterFilterProvider}
 * @returns filterMutator - A function that can be used to modify the filter state
 * - if the context is filtering on SubAccounts, the filterMutator will remove the SubAccounts from the blotter's internal accordion mechanism
 * @returns additionalFilterState - The current filter state for the context
 * - to be used in useEffects or related hooks to update the filter state
 */
export function useContextBlotterFilter<T extends ContextBlotterFilterMergeArg = object>(): {
  filterMutator: ({ initialFilter, filterBuilderProps }: T) => T;
  additionalFilterState: ContextBlotterFilterState | undefined;
} {
  const { filter } = useContextBlotterFilterProvider();

  if (!filter) {
    return {
      additionalFilterState: undefined,
      filterMutator: item => item,
    };
  }

  return {
    additionalFilterState: filter?.state,
    filterMutator: (arg: T): T => {
      const { initialFilter, filterBuilderProps, filterableProperties, changeFilter } = arg;
      if (!filter) {
        return arg;
      }
      return {
        ...arg,
        initialFilter: initialFilter
          ? {
              ...initialFilter,
              SubAccounts: filter.state.SubAccounts ?? initialFilter.SubAccounts,
              MarketAccounts: filter.state.MarketAccounts ?? initialFilter.MarketAccounts,
            }
          : undefined,
        filterBuilderProps: filterBuilderProps
          ? {
              ...filterBuilderProps,
              properties:
                arg.filterBuilderProps?.properties?.filter(
                  ({ key }) => ![filter.key].includes(key as keyof ContextBlotterFilterState)
                ) ?? [],
            }
          : undefined,
        filterableProperties: filterableProperties
          ? filterableProperties.filter(({ key }) => ![filter.key].includes(key as keyof ContextBlotterFilterState))
          : undefined,
        changeFilter: changeFilter
          ? action => {
              changeFilter(filter => {
                const newFilter = action instanceof Function ? action(filter) : action;
                return {
                  ...newFilter,
                  SubAccounts: filter.state.SubAccounts ?? newFilter.SubAccounts,
                  MarketAccounts: filter.state.MarketAccounts ?? newFilter.MarketAccounts,
                };
              });
            }
          : undefined,
      };
    },
  };
}
