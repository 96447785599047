import type { ITooltipParams, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import Big from 'big.js';
import { get, isArray } from 'lodash';
import { Order } from '../../../types/Order';
import { Trade } from '../../../types/Trade';

import {
  AllocationValueTypeEnum,
  AssetTransaction,
  type IAllocation4213Allocations,
  type ITradeAllocation4212Allocations,
} from '../../../types';
import { baseColumn } from './baseColumn';
import type { ColDefFactory, Column } from './types';

type SubAccountColumnValue = string | string[];

// Todo: this column should be redone and renamed to "allocations"
export const subAccount: ColDefFactory<Column> = column => ({
  ...baseColumn(column),
  tooltipValueGetter: ({ data, context, valueFormatted }: ITooltipParams<unknown, SubAccountColumnValue>) => {
    let currency: string | undefined = undefined;
    let valueType: AllocationValueTypeEnum | undefined = undefined;
    let allocations: IAllocation4213Allocations[] | ITradeAllocation4212Allocations[] = [];

    if (
      (data instanceof Order || data instanceof Trade || data instanceof AssetTransaction) &&
      data.Allocation &&
      data.Allocation.ValueType
    ) {
      allocations = data.Allocation.Allocations;
      valueType = data.Allocation.ValueType;
      currency = data instanceof AssetTransaction ? data.Asset : data.Currency;

      if (allocations.length) {
        return allocations
          .map((alloc: (typeof allocations)[number]) => {
            const name = context.current.subAccountsByName?.get(alloc.SubAccount)?.DisplayName ?? alloc.SubAccount;
            return valueType === AllocationValueTypeEnum.Percentage
              ? `${Big(alloc.Value || 0).times(100)}% ${name}`
              : `${alloc.Value} ${currency || 'Qty'} ${name}`;
          })
          .join(',\n');
      }
    }

    return valueFormatted ?? '';
  },
  valueGetter: ({ data, context }: ValueGetterParams<any>): SubAccountColumnValue => {
    if (data instanceof Order || data instanceof Trade || data instanceof AssetTransaction) {
      if (data.SubAccount) {
        return data.SubAccount;
      } else if (data.Allocation?.Allocations) {
        return data.Allocation.Allocations.map(alloc => alloc.SubAccount);
      }
    }

    const resolveName = (value: string | number): string => {
      const subAccountByID = context.current.subAccountsByID;
      const subAccountByName = context.current.subAccountsByName;

      if (typeof value === 'number' && subAccountByID?.has(value)) {
        return subAccountByID.get(value)?.DisplayName ?? String(value);
      }

      return subAccountByName?.get(String(value))?.DisplayName ?? value ? String(value) : '';
    };

    let subAccountNameOrId: string | number | string[] | number[] = '';

    if (column.field) {
      subAccountNameOrId = get(data, column.field);
    }
    if (data.SubAccount) {
      subAccountNameOrId = data.SubAccount;
    }

    if (isArray(subAccountNameOrId)) {
      return subAccountNameOrId.map((identifier: string | number) => resolveName(identifier) ?? identifier);
    }
    return resolveName(subAccountNameOrId);
  },
  valueFormatter: ({ value }: ValueFormatterParams<SubAccountColumnValue>): string => {
    if (isArray(value)) {
      return value.map(v => v).join(', ');
    }

    return value;
  },
});
