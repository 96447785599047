import {
  Box,
  CurrencyIcon,
  Divider,
  Flex,
  HStack,
  Icon,
  InlineFormattedNumber,
  PositionThresholdMeter,
  Text,
  iconByHedgePositionStatus,
  iconColorByHedgePositionStatus,
  type IHedgeRule,
} from '@talos/kyoko';

import styled from 'styled-components';
import { useSubAccounts } from '../../providers';
import type { IHedgePositionStatusRow } from '../../providers/HedgePositionStatusProvider';

/**
 * Card to display the current position and status of a hedge rule. Optionally leverages the status to render a meter.
 */
export function PositionAutoHedgingCard({
  hedgePositionStatus,
  hedgeRule,
}: {
  hedgePositionStatus?: IHedgePositionStatusRow;
  hedgeRule: IHedgeRule;
}) {
  const { subAccountsByID } = useSubAccounts();

  const account = subAccountsByID.get(hedgeRule.AccountID);
  const accountDisplay = account?.DisplayName ?? account?.Name ?? hedgeRule.AccountID;

  return (
    <CardWrapper fontSize="fontSizeSmall">
      <HStack justifyContent="space-between">
        <Text>Account</Text>
        <Text color="colorTextImportant">{accountDisplay}</Text>
      </HStack>
      <HStack justifyContent="space-between">
        <Text>Asset</Text>
        <HStack color="colorTextImportant">
          <CurrencyIcon currency={hedgeRule.Asset} size={12} />
          <Box ml="spacingSmall">
            <Text>{hedgeRule.Asset}</Text>
          </Box>
        </HStack>
      </HStack>
      {hedgePositionStatus && (
        <>
          <HStack justifyContent="space-between" data-testid="hedge-control-status">
            <Text>Status</Text>
            <Flex gap="spacingSmall" color="colorTextImportant">
              <Icon
                size={12}
                icon={iconByHedgePositionStatus[hedgePositionStatus.HedgeControlStatus]}
                color={iconColorByHedgePositionStatus[hedgePositionStatus.HedgeControlStatus]}
              />
              <Text>{hedgePositionStatus.HedgeControlStatus ?? '-'}</Text>
            </Flex>
          </HStack>
          <Divider />
          <HStack justifyContent="space-between">
            <Text>Current Position</Text>
            <InlineFormattedNumber
              number={hedgePositionStatus.CurrentPosition}
              round
              currency={hedgePositionStatus.TargetAsset}
            />
          </HStack>

          <PositionThresholdMeter value={hedgePositionStatus} />
        </>
      )}
    </CardWrapper>
  );
}

const CardWrapper = styled(Flex)`
  flex-direction: column;
  gap: ${({ theme }) => theme.spacingDefault}px;
  background-color: ${({ theme }) => theme.backgroundCard};
  border-radius: ${({ theme }) => theme.borderRadiusCard}px;
  padding: ${({ theme }) => theme.spacingDefault}px;
`;
