import {
  type AggregationWithAccounts,
  Flex,
  Icon,
  IconName,
  MarketSelectionsList,
  SearchSelect,
  type SearchSelectProps,
  type SearchSelectRef,
} from '@talos/kyoko';
import { useMemo } from 'react';

export function AggregationsSelector(
  props: SearchSelectProps<AggregationWithAccounts> & { ref?: React.ForwardedRef<SearchSelectRef> } & {
    showMarketSelections?: boolean;
  }
) {
  const { selection, showMarketSelections = true } = props;

  const selections = useMemo(() => {
    return selection?.Accounts ? [...selection.Accounts.keys()] : null;
  }, [selection]);

  return (
    <Flex gap="spacingDefault" flexDirection="column">
      <SearchSelect {...props} prefix={<Icon icon={IconName.CommandCmd} color="purple.default" />} />
      {showMarketSelections && selections && <MarketSelectionsList h="100px" selections={selections} />}
    </Flex>
  );
}
