import { memo } from 'react';
import styled from 'styled-components';

import { SymbolSelector as SymbolSelectorComponent } from './SymbolSelector';

export { SymbolSelectorAppearance, type SymbolSelectorProps } from './types';

const MemoizedSymbolSelector = memo(SymbolSelectorComponent) as typeof SymbolSelectorComponent;

export const SymbolSelector = styled(MemoizedSymbolSelector)``;
