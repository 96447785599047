import { transparentize } from 'polished';
import styled, { css } from 'styled-components';
import { Box, HStack } from '../Core';

export enum IndicatorBadgeVariants {
  Default = 'DEFAULT',
  Notification = 'NOTIFICATION',
  Positive = 'POSITIVE',
  Negative = 'NEGATIVE',
}

export enum IndicatorBadgeSizes {
  Small = 'Small',
  Large = 'Large',
}

export const Wrapper = styled(HStack)`
  display: inline-flex;
  gap: ${({ theme }) => theme.spacingSmall}px;
  cursor: default;
`;

export const IndicatorBadgeWrapper = styled(Box)<{
  variant: IndicatorBadgeVariants;
  size: IndicatorBadgeSizes;
}>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => theme.borderRadiusDefault}px;
  text-align: center;

  ${({ size, theme }) =>
    size === IndicatorBadgeSizes.Small &&
    css`
      padding: ${theme.spacingTiny}px ${theme.spacingSmall + 1}px;
      font-size: ${theme.fontSizeTiny}rem;
      line-height: ${theme.lineHeightTiny}rem;
    `}
  ${({ size, theme }) =>
    size === IndicatorBadgeSizes.Large &&
    css`
      padding: ${theme.spacingTiny}px ${theme.spacingDefault - 1}px;
      font-size: ${theme.fontSizeSmall}rem;
      line-height: ${theme.lineHeightSmall}rem;
    `}

  ${({ variant, theme }) =>
    variant === IndicatorBadgeVariants.Default &&
    css`
      background: ${transparentize(0.92, theme.colors.gray['100'])};
      color: ${theme.colors.gray['090']};
    `};
  ${({ variant, theme }) =>
    variant === IndicatorBadgeVariants.Notification &&
    css`
      background: ${theme.colors.orange.default};
      color: ${theme.colors.white.default};
    `}
  ${({ variant, theme }) =>
    variant === IndicatorBadgeVariants.Negative &&
    css`
      background: ${transparentize(0.72, theme.colors.red.default)};
      color: ${theme.colors.red.lighten};
    `}
    
    ${({ variant, theme }) =>
    variant === IndicatorBadgeVariants.Positive &&
    css`
      background: ${transparentize(0.72, theme.colors.green.default)};
      color: ${theme.colors.green.lighten};
    `}
`;
