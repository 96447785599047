import * as Sentry from '@sentry/browser';
import { addDays, addMinutes, differenceInSeconds, isPast, isValid, startOfMinute } from 'date-fns';
import type { FocusEvent } from 'react';
import { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import type { DayPickerProps } from 'react-day-picker';
import { useTheme } from 'styled-components';
import { FormControlSizes, type FormControlProps } from '../Form/types';

import { isEqual } from 'lodash';
import { useElementId, useNamedState } from '../../hooks';
import { formattedDate, isValidDateInput, parseDate } from '../../utils';
import { Button, ButtonGroup, ButtonVariants, IconButton, ToggleButton } from '../Button';
import { Flex, HStack, VStack } from '../Core';
import { DEFAULT_DATE_PICKER_EOD } from '../DateTimePicker';
import { DayPicker } from '../DayPicker';
import { Input } from '../Form';
import { Icon, IconName } from '../Icons';
import { Popover, usePopoverState } from '../Popover';
import { TimePicker } from '../TimePicker';
import { TimePickerPrecision } from '../TimePicker/TimePickerPrecision';
import { DEFAULT_DURATION_SHORTCUTS, DurationPicker } from './DurationPicker';
import {
  calcDateFromDuration,
  canParseAsDuration,
  emptyDuration,
  formatDuration,
  isDuration,
  parseDuration,
  type Duration,
} from './duration';
import {
  DateDescription,
  DateTimeDurationPickerWrapper,
  DateTimeWrapper,
  DurationDescription,
  InputOverlay,
  PopoverWrapper,
  TimePickerWrapper,
} from './styles';

export { DurationPicker } from './DurationPicker';
export {
  calcDateFromDuration,
  emptyDuration,
  formatDuration,
  isDuration,
  isEmptyDuration,
  parseDuration,
} from './duration';
export type { Duration } from './duration';

export enum DateTimeDurationPickerValueType {
  DateTime = 'DateTime',
  Duration = 'Duration',
}

export function isDateTimeDurationPickerValue(value: any): value is DateTimeDurationPickerValue {
  return (
    value?.type === DateTimeDurationPickerValueType.DateTime || value?.type === DateTimeDurationPickerValueType.Duration
  );
}

export type DateTimeDurationPickerValue =
  | {
      type: DateTimeDurationPickerValueType.DateTime;
      value: Date | null;
    }
  | {
      type: DateTimeDurationPickerValueType.Duration;
      value: Duration;
    };

export interface DateTimeDurationPickerProps {
  /** Current value of the date / time picker */
  value: Duration | Date | null;
  /** Change event handler */
  onChange: (value: DateTimeDurationPickerValue) => void;
  /** Show the shortcuts section (5m/10m/...)? */
  showShortcuts?: boolean;
  /** Shortcuts to show
   *
   * @default {@link DEFAULT_SHORTCUTS}
   */
  shortcuts?: { [key: string]: string };
  /** Label to use on the Date/Time toggle button
   *
   * @default Date/Time
   */
  dateTimeLabel?: string;
  /** Label to use on the Duration toggle button
   *
   * @default Duration
   */
  durationLabel?: string;
  calcDurationRelativeTo?: Date | null;
  disabled?: boolean;
  /** Time to use as End-Of-Day
   *
   * @default {@link DEFAULT_DATE_PICKER_EOD}
   */
  customEOD?: { hours: number; minutes: number };
  /** Portalize component: @default: true */
  portalize?: boolean;
  initialEntryMode?: DateTimeDurationPickerValueType;

  entryModes?: DateTimeDurationPickerValueType[];
  dataTestId?: string;
}

type ForwardedProps = Omit<FormControlProps<HTMLInputElement>, keyof DateTimeDurationPickerProps | 'prefix'>;

/**
 * Date / Time Picker component
 */
export const DateTimeDurationPicker = memo(function DateTimeDurationPicker({
  value,
  shortcuts = DEFAULT_DURATION_SHORTCUTS,
  showShortcuts = true,
  onChange,
  dateTimeLabel = 'Date/Time',
  durationLabel = 'Duration',
  customEOD = DEFAULT_DATE_PICKER_EOD,
  calcDurationRelativeTo,
  disabled,
  portalize = true,
  size,
  initialEntryMode,
  dataTestId,
  entryModes = [DateTimeDurationPickerValueType.DateTime, DateTimeDurationPickerValueType.Duration],
  id,
  ...props
}: DateTimeDurationPickerProps & ForwardedProps) {
  const theme = useTheme();
  const idGenerator = useElementId(id ?? 'DateTimeDurationPicker');
  const [isFocused, setIsFocused] = useNamedState(false, 'isFocused');
  const [entryMode, setEntryMode] = useNamedState(
    initialEntryMode && !value && entryModes.includes(initialEntryMode)
      ? initialEntryMode
      : isDuration(value)
      ? DateTimeDurationPickerValueType.Duration
      : DateTimeDurationPickerValueType.DateTime,
    'entryMode'
  );
  const [inputValue, setInputValue] = useNamedState<string>('', 'inputValue');
  const [internalTouched, setInternalTouched] = useNamedState<boolean>(false, 'internalTouched');
  const [selectedDuration, _setSelectedDuration] = useNamedState<Duration>(
    isDuration(value) ? value : emptyDuration,
    'selectedDuration'
  );
  const [selectedDate, _setSelectedDate] = useNamedState<Date | null>(
    isDuration(value) || !isValidDateInput(value) || value === null ? null : parseDate(value),
    'selectedDate'
  );
  const inputRef = useRef<HTMLInputElement>(null);
  const popoverRef = useRef<HTMLDivElement>(null);
  const [selectedTime, setSelectedTime] = useNamedState<Date>(
    selectedDate ? parseDate(selectedDate) : parseDate(),
    'selectedTime'
  );
  const isFocusOutsideComponent = useCallback(
    (newTarget: EventTarget | null) => {
      if (inputRef.current?.contains(newTarget as Node | null)) {
        return false;
      }
      if (popoverRef.current?.contains(newTarget as Node | null)) {
        return false;
      }
      return true;
    },
    [inputRef, popoverRef]
  );
  const popover = usePopoverState({
    trigger: '',
    placement: 'bottom-end',
    modifiers: [
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['top', 'bottom'],
        },
      },
    ],
    delay: undefined,
    usePortal: portalize,
    onClickOutside: e => {
      if (isFocusOutsideComponent(e.target)) {
        if (internalTouched) {
          // if the user has change the value at all, then we'll act as if they clicked "Set" when they blur the control
          handleClickSet();
        } else {
          // Otherwise, if they made no changes, act as if they click cancel
          handleClickCancel();
        }
      }
    },
    onClose: () => {},
  });
  const { open: openPopup, close: closePopup } = popover;

  const handleFocus = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      if (!isFocused) {
        if (entryMode === DateTimeDurationPickerValueType.DateTime) {
          let newSelectedTime = selectedDate ? parseDate(selectedDate) : parseDate();
          if (!selectedDate) {
            // if we don't have a value yet, default the time to the start of the next minute
            newSelectedTime = addMinutes(startOfMinute(newSelectedTime), 1);
            if (differenceInSeconds(newSelectedTime, parseDate()) < 10) {
              // ensure our default time is at least 10 seconds from now
              newSelectedTime = addMinutes(newSelectedTime, 1);
            }
          }
          setSelectedTime(newSelectedTime);
        }
      }

      setIsFocused(true);
      openPopup();
    },
    [entryMode, isFocused, openPopup, selectedDate, setSelectedTime, setIsFocused]
  );

  useEffect(() => {
    if (!isFocused) {
      if (isDuration(value)) {
        setInputValue(formatDuration(value));
      } else {
        setInputValue(value && isValidDateInput(value) ? formattedDate(value) : '');
      }
      _setSelectedDuration(isDuration(value) ? value : emptyDuration);
      _setSelectedDate(isDuration(value) || !isValidDateInput(value) || value === null ? null : parseDate(value));
      setInternalTouched(false);
    }
  }, [value, isFocused, setInputValue, _setSelectedDuration, _setSelectedDate, setInternalTouched]);

  const setDateTimeEntryMode = useCallback(() => {
    setEntryMode(DateTimeDurationPickerValueType.DateTime);
    setInputValue(isValidDateInput(selectedTime) ? formattedDate(selectedTime) : '');
  }, [selectedTime, setEntryMode, setInputValue]);
  const setDurationEntryMode = useCallback(() => {
    setEntryMode(DateTimeDurationPickerValueType.Duration);
    setInputValue(formatDuration(selectedDuration));
  }, [selectedDuration, setEntryMode, setInputValue]);

  const handleDurationChange = useCallback(
    (newDuration: Duration) => {
      _setSelectedDuration(newDuration);
      setInputValue(formatDuration(newDuration));
      setInternalTouched(true);
    },
    [_setSelectedDuration, setInputValue, setInternalTouched]
  );

  const handleDateChange = useCallback(
    (newDate: Date | null) => {
      newDate = isValidDateInput(newDate) ? newDate : null;
      _setSelectedDate(newDate);
      setSelectedTime(newDate ?? selectedTime);
      setInputValue(formattedDate(newDate ?? selectedTime));
      setInternalTouched(true);
    },
    [_setSelectedDate, selectedTime, setInputValue, setInternalTouched, setSelectedTime]
  );

  const setValueFromString = useCallback(
    (newValue: string) => {
      setInternalTouched(true);
      if (
        canParseAsDuration(newValue) ||
        (!isValid(parseDate(newValue)) && entryMode === DateTimeDurationPickerValueType.Duration)
      ) {
        const newDuration = parseDuration(newValue);
        _setSelectedDuration(newDuration);
        setEntryMode(DateTimeDurationPickerValueType.Duration);
        return { date: null, duration: newDuration };
      } else {
        setEntryMode(DateTimeDurationPickerValueType.DateTime);
        if (!newValue) {
          _setSelectedDate(null);
          return { date: null, duration: null };
        } else {
          const newDate = parseDate(newValue);
          if (isValidDateInput(newDate)) {
            _setSelectedDate(newDate);
            setSelectedTime(newDate);
          }
          return { date: newDate, duration: null };
        }
      }
    },
    [setInternalTouched, entryMode, _setSelectedDuration, setEntryMode, _setSelectedDate, setSelectedTime]
  );
  const handleInputTextBoxChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    e => {
      setValueFromString(e.target.value);
      setInputValue(e.target.value);
      setInternalTouched(true);
    },
    [setInputValue, setInternalTouched, setValueFromString]
  );

  const handleClickCancel = useCallback(() => {
    if (isDuration(value)) {
      setInputValue(formatDuration(value));
    } else {
      setInputValue(value ? formattedDate(value) : '');
    }
    setIsFocused(false);
    closePopup();
  }, [value, closePopup, setInputValue, setIsFocused]);

  const maybeEmitChange = useCallback(
    (newEntryMode: DateTimeDurationPickerValueType, newDate: Date | null, newDuration: Duration | null) => {
      if (newEntryMode === DateTimeDurationPickerValueType.Duration) {
        if (!isEqual(newDuration, value)) {
          onChange({ type: DateTimeDurationPickerValueType.Duration, value: newDuration ?? emptyDuration });
        }
      } else if (!isEqual(newDate, value)) {
        onChange({ type: DateTimeDurationPickerValueType.DateTime, value: newDate });
      }
    },
    [onChange, value]
  );

  const clearValue = useCallback(() => {
    setInternalTouched(true);
    const { date: newDate, duration: newDuration } = setValueFromString('');
    setInputValue('');
    maybeEmitChange(entryMode, newDate, newDuration);
    closePopup();
  }, [setInternalTouched, setValueFromString, setInputValue, maybeEmitChange, entryMode, closePopup]);

  const handleSelectDay = useCallback(
    (newDate: Date, modifiers, s) => {
      const { disabled } = modifiers;
      setInternalTouched(true);
      if (disabled) {
        // Day is disabled, do nothing
        return;
      }
      // Note that the DayPicker returns a date with time = 12:00:00.000
      if (selectedDate === null) {
        newDate.setHours(customEOD.hours);
        newDate.setMinutes(customEOD.minutes);
        newDate.setSeconds(0);
        newDate.setMilliseconds(0);
      } else {
        newDate.setHours(selectedTime.getHours());
        newDate.setMinutes(selectedTime.getMinutes());
        newDate.setSeconds(selectedTime.getSeconds());
        newDate.setMilliseconds(selectedTime.getMilliseconds());
      }
      if (isValidDateInput(newDate)) {
        setInputValue(formattedDate(newDate));
      } else {
        Sentry.captureMessage('Received invalid date in date/time/duration picker', {
          level: 'error',
          extra: {
            newDate,
            customEOD,
          },
        });
      }
      handleDateChange(newDate);
    },
    [setInternalTouched, selectedDate, handleDateChange, customEOD, selectedTime, setInputValue]
  ) satisfies DayPickerProps['onDayClick'];
  const handleTimeChange = useCallback(
    (newDate: Date) => {
      setInternalTouched(true);
      handleDateChange(newDate);
    },
    [handleDateChange, setInternalTouched]
  );

  const setTimeToEOD = useCallback(() => {
    setInternalTouched(true);
    let newDate = parseDate(selectedDate);
    newDate.setHours(customEOD.hours);
    newDate.setMinutes(customEOD.minutes);
    newDate.setSeconds(0);
    newDate.setMilliseconds(0);
    if (isPast(newDate)) {
      newDate = addDays(newDate, 1);
    }

    handleDateChange(newDate);
  }, [setInternalTouched, selectedDate, customEOD.hours, customEOD.minutes, handleDateChange]);

  const closeAndSetDuration = useCallback(
    newDuration => {
      handleDurationChange(newDuration);
      maybeEmitChange(entryMode, selectedDate, newDuration);
      setIsFocused(false);
      closePopup();
    },
    [entryMode, selectedDate, maybeEmitChange, closePopup, handleDurationChange, setIsFocused]
  );
  const handleClickSet = useCallback(() => {
    // Note: we use `selectedTime` here because the time picker defaults to "now" when no value is set.
    // If the user has entered nothing, but clicks Set, we'd want it to set the value they see in the timepicker
    maybeEmitChange(entryMode, selectedTime, selectedDuration);
    setIsFocused(false);
    closePopup();
  }, [entryMode, selectedTime, selectedDuration, maybeEmitChange, closePopup, setIsFocused]);

  const onBlur: React.FocusEventHandler<HTMLInputElement> = useCallback(
    (e: FocusEvent) => {
      if (e.relatedTarget && isFocusOutsideComponent(e.relatedTarget)) {
        if (internalTouched) {
          // if the user has change the value at all, then we'll act as if they clicked "Set" when they blur the control
          handleClickSet();
        } else {
          // Otherwise, if they made no changes, act as if they click cancel
          handleClickCancel();
        }
      }
    },
    [isFocusOutsideComponent, internalTouched, handleClickSet, handleClickCancel]
  );
  const showClear = useMemo(() => inputValue !== '' && !disabled, [disabled, inputValue]);

  return (
    <DateTimeDurationPickerWrapper {...props} onFocus={handleFocus} onBlur={onBlur} focused={isFocused}>
      <Input
        {...props}
        data-testid={dataTestId}
        size={size}
        disabled={disabled}
        value={inputValue}
        onChange={handleInputTextBoxChange}
        ref={inputRef}
        id={id ?? idGenerator('value')}
        aria-describedby={idGenerator('description')}
        aria-label={props['aria-label'] ?? 'Value'}
        suffix={
          <>
            <InputOverlay
              id={idGenerator('description')}
              role="note"
              aria-label={`${props['aria-label'] ?? 'Value'} Description`}
              size={size}
            >
              {entryMode === DateTimeDurationPickerValueType.Duration ? (
                <>
                  <DurationDescription disabled={disabled}>{formatDuration(selectedDuration)}</DurationDescription>
                  {!isEqual(selectedDuration, emptyDuration) && isValidDateInput(calcDurationRelativeTo) ? (
                    <DateDescription disabled={disabled}>
                      &nbsp;({formattedDate(calcDateFromDuration(selectedDuration, calcDurationRelativeTo))})
                    </DateDescription>
                  ) : null}
                </>
              ) : isValidDateInput(selectedDate) ? (
                <DateDescription disabled={disabled}>{formattedDate(selectedDate)}</DateDescription>
              ) : null}
            </InputOverlay>
            <IconButton
              ghost
              round
              size={(size ?? FormControlSizes.Default) - 0.5}
              icon={IconName.Clear}
              onClick={clearValue}
              style={{ visibility: showClear ? 'visible' : 'hidden' }}
              tabIndex={-1}
              aria-label="Clear value"
            />
            <Popover {...popover} tabIndex={-1}>
              <Icon
                icon={IconName.Clock}
                aria-label="Open"
                role="button"
                spaceAfter="spacingSmall"
                style={{ marginRight: '4px', visibility: disabled ? 'hidden' : 'visible' }}
              />
              <PopoverWrapper ref={popoverRef}>
                <HStack h="100%" w="100%" justifyContent="space-between">
                  {entryMode === DateTimeDurationPickerValueType.DateTime ? (
                    <DateTimeWrapper role="tabpanel" aria-labelledby={idGenerator('date-time')}>
                      <DayPicker
                        numberOfMonths={1}
                        onDayClick={handleSelectDay}
                        /* dates passed to DayPicker must be valid dates or else it crashes */
                        selectedDays={selectedDate && isValid(selectedDate) ? selectedDate : undefined}
                        disabledDays={{ before: parseDate() }}
                        month={selectedDate && isValid(selectedDate) ? selectedDate : undefined}
                      />
                      <TimePickerWrapper>
                        <TimePicker
                          date={selectedTime}
                          onChange={handleTimeChange}
                          maxPrecision={TimePickerPrecision.SECOND}
                        />
                        <Button onClick={setTimeToEOD}>EOD</Button>
                      </TimePickerWrapper>
                    </DateTimeWrapper>
                  ) : (
                    <DurationPicker
                      value={selectedDuration}
                      onChange={handleDurationChange}
                      closeAndSetValue={closeAndSetDuration}
                      showShortcuts={showShortcuts}
                      shortcuts={shortcuts}
                      role="tabpanel"
                      aria-labelledby={idGenerator('duration')}
                      id={idGenerator('duration-picker')}
                    />
                  )}
                  <VStack
                    justifyContent="stretch"
                    alignSelf="stretch"
                    p="spacingComfortable"
                    style={{ background: theme.backgroundPopoverHighlight }}
                  >
                    {entryModes.length > 1 && (
                      <ButtonGroup orientation="vertical" role="tablist">
                        {entryModes.includes(DateTimeDurationPickerValueType.DateTime) && (
                          <ToggleButton
                            selected={entryMode === DateTimeDurationPickerValueType.DateTime}
                            onClick={setDateTimeEntryMode}
                            style={{ width: '100%' }}
                            role="tab"
                            aria-selected={entryMode === DateTimeDurationPickerValueType.DateTime}
                            id={idGenerator('date-time')}
                          >
                            {dateTimeLabel}
                          </ToggleButton>
                        )}
                        {entryModes.includes(DateTimeDurationPickerValueType.Duration) && (
                          <ToggleButton
                            selected={entryMode === DateTimeDurationPickerValueType.Duration}
                            onClick={setDurationEntryMode}
                            style={{ width: '100%' }}
                            role="tab"
                            aria-selected={entryMode === DateTimeDurationPickerValueType.Duration}
                            id={idGenerator('duration')}
                          >
                            {durationLabel}
                          </ToggleButton>
                        )}
                      </ButtonGroup>
                    )}
                    <Flex flex="1" h="100%" />
                    <Button
                      variant={ButtonVariants.Default}
                      style={{ width: '100%', marginBottom: theme.spacingDefault + 'px' }}
                      onClick={handleClickCancel}
                    >
                      Cancel
                    </Button>
                    <Button style={{ width: '100%' }} onClick={handleClickSet}>
                      Set
                    </Button>
                  </VStack>
                </HStack>
              </PopoverWrapper>
            </Popover>
          </>
        }
      />
    </DateTimeDurationPickerWrapper>
  );
});
