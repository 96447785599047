import { useMemo } from 'react';
import { useTheme } from 'styled-components';
import { useMarketAccountsContext, useMarketsContext } from '../../contexts';
import { IconButton } from '../Button';
import { Box, type BoxProps, Flex, HStack, VStack } from '../Core';
import { FormControlSizes } from '../Form';
import { IconName } from '../Icons';
import { MarketLogo } from '../MarketLogos';
import { Text } from '../Text';

interface MarketSelectionsListProps {
  /** A selection is allowed to be either a Market.Name or a MarketAccount.Name. The array can also be a mixture of these. */
  selections: string[];
  onDelete?: (selection: string) => void;
  h?: BoxProps['h'];
}

export const MarketSelectionsList = ({ selections, onDelete, h }: MarketSelectionsListProps) => {
  if (selections.length === 0) {
    return (
      <HStack w="100%" m="spacingComfortable">
        <Text>Nothing selected</Text>
      </HStack>
    );
  }

  return (
    <VStack w="100%" fontSize="fontSizeSmall" gap="spacingSmall" data-testid="market-selections">
      <HStack w="100%" justifyContent="space-between">
        <Text>Selected Markets</Text>
        {/* More right side items coming here... */}
      </HStack>

      <Flex
        w="100%"
        gap="spacingSmall"
        mb="spacingDefault"
        flexDirection="column"
        maxHeight={h}
        overflow={h ? 'auto' : undefined}
      >
        {selections.map(selection => (
          <SelectionItem key={selection} selection={selection} onDelete={onDelete} />
        ))}
      </Flex>
    </VStack>
  );
};

type SelectionItemProps = Omit<MarketSelectionsListProps, 'selections'> & { selection: string };

const SelectionItem = ({ selection, onDelete }: SelectionItemProps) => {
  const theme = useTheme();
  const { marketsByName } = useMarketsContext();
  const { marketAccountsByName } = useMarketAccountsContext();

  // selection is either a market name or market account name. Resolve to market and market account.
  const { market, marketAccount } = useMemo(() => {
    const mktAcc = marketAccountsByName.get(selection);
    if (mktAcc) {
      const mkt = marketsByName.get(mktAcc.Market);
      return { market: mkt, marketAccount: mktAcc };
    }

    // else selection is a market name (or something is wrong ofc)
    const mkt = marketsByName.get(selection);
    return { market: mkt, marketAccount: undefined };
  }, [selection, marketsByName, marketAccountsByName]);

  if (!market) {
    return null;
  }

  const label = marketAccount?.DisplayName ?? marketAccount?.Name ?? market.DisplayName ?? market.Name;

  return (
    <Box
      w="100%"
      background={theme.backgroundDefaultButton}
      px="spacingDefault"
      py="spacingSmall"
      borderRadius={theme.borderRadiusDefault}
      data-testid={`market-selection-item/${market.Name}`}
    >
      <HStack w="100%" justifyContent="space-between">
        {/* Left bit */}
        <HStack gap="spacingSmall" color="colorTextImportant">
          <MarketLogo size={15} market={market.Name} />
          {/* Pointer-events none because the text cursor is ugly and feels bad */}
          <Text style={{ cursor: 'default' }}>{label}</Text>
        </HStack>

        {/* Right bit */}
        <HStack>
          {onDelete && (
            <IconButton ghost size={FormControlSizes.Tiny} icon={IconName.Close} onClick={() => onDelete(selection)} />
          )}
        </HStack>
      </HStack>
    </Box>
  );
};
