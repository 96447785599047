import type { UseComboboxStateChange } from 'downshift';
import type { Security } from '../../types';

import type { FlexProps } from '../Core';
import type { FormControlSizes } from '../Form';
import type { ExposedAutocompleteDropdownProps } from '../Form/AutocompleteDropdown/types';

export enum SymbolSelectorAppearance {
  Basic = 'Basic',
  Ghost = 'Ghost',
  Search = 'Search',
  CurrencyPair = 'CurrencyPair',
}

export type SymbolSelectorProps = {
  className?: string;
  startOpen?: boolean;
  symbol?: string;
  /** Pass in your own list of Security[] to override the default one */
  availableSecurities?: Security[];
  /**
   * If passing this prop, you may need to do some cleanup of your state
   * for your input elements. The default behavior inside of useSearchSelect
   * clears the input value and focuses the element itself on open, and sets
   * it to the selected item's appropriate label on selection.
   */
  onIsOpenChange?: ((changes: UseComboboxStateChange<Security>) => void) | undefined;
  onSymbolChanged: (symbol?: string) => void;
  disabled?: boolean;
  appearance: SymbolSelectorAppearance;
  size?: FormControlSizes;
  showClear?: boolean;
  showGroups?: boolean;
  dropdownId?: string;
  /** For the Basic select appearance, whether or not to center the select header contents */
  centerBasicSelect?: boolean;
  showFavorites?: boolean;
  showSelectedSymbolDescription?: boolean;
} & ExposedAutocompleteDropdownProps &
  FlexProps;
