import {
  HEDGE_POSITION_STATUS,
  ORDER,
  useObservable,
  useObservableValue,
  useSubscription,
  wsScanToMap,
  wsStitchWith,
  wsSubscriptionCache,
  type IHedgePositionStatus,
  type MinimalSubscriptionResponse,
  type Order,
} from '@talos/kyoko';
import { pick } from 'lodash';
import { createContext, useContext, useMemo, type PropsWithChildren } from 'react';
import { map, shareReplay, type Observable } from 'rxjs';
import { useFeatureFlag } from '../hooks/useFeatureFlag';
import { OPEN_ORDER_STATUSES } from './constants';

type HedgePositionStatusContextProps = {
  /**
   * Observable of the latest hedge position status deltas. Useful for tables
   */
  hedgePositionStatusDeltasObs: Observable<MinimalSubscriptionResponse<IHedgePositionStatusRow>>;
  /**
   * Observable of the lastest hedge position status in a lookup map. Useful for quick lookups.
   */
  hedgePositionStatusObs: Observable<Map<string, IHedgePositionStatusRow>>;
  /**
   * Observable for the global status of the autohedger.
   */
  globalHedgePositionStatusObs: Observable<MinimalSubscriptionResponse<IHedgePositionStatus, string>>;

  /**
   * The global hedge rule, if any.
   */
  globalHedgeRule: IHedgePositionStatus | undefined;
};

const HedgePositionStatusContext = createContext<HedgePositionStatusContextProps | null>(null);

export type IHedgePositionStatusOrder = Pick<
  Order,
  'AvgPx' | 'CumQty' | 'Side' | 'Symbol' | 'OrderID' | 'Currency' | 'Strategy'
>;

export interface IHedgePositionStatusRow extends IHedgePositionStatus {
  Order?: IHedgePositionStatusOrder;
}

export function HedgePositionStatusProvider({ children }: PropsWithChildren) {
  const { enableAutoHedging, enableOrderLinkInHedgeRule } = useFeatureFlag();

  const hedgeSubRequest = useMemo(() => {
    return enableAutoHedging
      ? {
          name: HEDGE_POSITION_STATUS,
          tag: 'HedgePositionStatusProvider_Position',
        }
      : null;
  }, [enableAutoHedging]);

  const globalHedgeSubRequest = useMemo(() => {
    return enableAutoHedging
      ? {
          name: HEDGE_POSITION_STATUS,
          tag: 'HedgePositionStatusProvider_Global',
          HedgeControlType: 'Global',
        }
      : null;
  }, [enableAutoHedging]);

  const ordersRequest = useMemo(() => {
    // We only request order data if both autohedging and order link in hedge rule are enabled
    return enableAutoHedging && enableOrderLinkInHedgeRule
      ? {
          name: ORDER,
          tag: 'HedgePositionStatusProvider',
          User: 'Staging Hedger Service', // TODO(robertz) This is hardcoded, and not intended to be used in the long term
          Statuses: OPEN_ORDER_STATUSES,
        }
      : null;
  }, [enableAutoHedging, enableOrderLinkInHedgeRule]);

  const { data: globalHedgePositionStatusObsRaw } = useSubscription<IHedgePositionStatus>(globalHedgeSubRequest);
  const globalHedgePositionStatusObs = useObservable(
    // Add cache to avoid late subscribers missing the initial data
    () =>
      globalHedgePositionStatusObsRaw.pipe(
        wsSubscriptionCache(hedgePositionStatus => hedgePositionStatus.HedgeRuleID, HEDGE_POSITION_STATUS)
      ),
    [globalHedgePositionStatusObsRaw]
  );

  const { data: hedgePositionStatusObsRaw } = useSubscription<IHedgePositionStatus>(hedgeSubRequest);
  const { data: ordersObs } = useSubscription<Order>(ordersRequest);

  const hedgePositionStatusDeltasObs: HedgePositionStatusContextProps['hedgePositionStatusDeltasObs'] = useObservable(
    () =>
      hedgePositionStatusObsRaw.pipe(
        wsStitchWith<IHedgePositionStatus, Order, IHedgePositionStatusRow>({
          secondarySource: ordersObs,
          getPrimaryTypeKey: hedgePositionStatus => hedgePositionStatus.HedgeRuleID,
          // The backlink to the HedgeRuleID is the Group field in the order, if the workflow is 'Hedging'.
          getSecondaryTypeKey: order =>
            // TODO(robertz): order.workflow does not exist yet, but when it does, we should use it here.
            /*order.Workflow === 'Hedging' && */ order.Group ? order.Group : '__NO_GROUP__',
          stitch: (hedgePositionStatus, order) => {
            if (!order) {
              return hedgePositionStatus;
            }
            return {
              ...hedgePositionStatus,
              Order: pick(order, [
                'Side',
                'CumQty',
                'AvgPx',
                'Symbol',
                'OrderID',
                'Currency',
                'Strategy',
              ] satisfies (keyof IHedgePositionStatusOrder)[]),
            };
          },
        }),
        wsSubscriptionCache(hedgePositionStatus => hedgePositionStatus.HedgeRuleID, HEDGE_POSITION_STATUS)
      ),
    [hedgePositionStatusObsRaw, ordersObs]
  );

  const hedgePositionStatusObs: HedgePositionStatusContextProps['hedgePositionStatusObs'] = useObservable(
    () =>
      hedgePositionStatusDeltasObs.pipe(
        wsScanToMap({ getUniqueKey: hedgePositionStatus => hedgePositionStatus.HedgeRuleID, newMapEachUpdate: true }),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [hedgePositionStatusDeltasObs]
  );

  const globalHedgeRule = useObservableValue(
    () =>
      globalHedgePositionStatusObs.pipe(
        map(json => {
          return json.data.at(0);
        })
      ),
    [globalHedgePositionStatusObs]
  );

  const value: HedgePositionStatusContextProps = useMemo(
    () => ({
      hedgePositionStatusObs,
      globalHedgePositionStatusObs,
      hedgePositionStatusDeltasObs,
      globalHedgeRule,
    }),
    [hedgePositionStatusObs, globalHedgePositionStatusObs, hedgePositionStatusDeltasObs, globalHedgeRule]
  );

  return <HedgePositionStatusContext.Provider value={value}>{children}</HedgePositionStatusContext.Provider>;
}

export function useHedgePositionStatus() {
  const context = useContext(HedgePositionStatusContext);
  if (context === null) {
    throw new Error('Missing HedgePositionStatusProvider further up in the tree. Did you forget to add it?');
  }
  return context;
}
