import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  creditUtilization: {
    defaultMessage: 'Credit utilization',
    id: 'BlotterTable.columns.creditUtilization',
  },
  deposit: {
    defaultMessage: 'Deposit',
    id: 'BlotterTable.columns.deposit',
  },
  filledPercent: {
    defaultMessage: 'Filled %',
    id: 'BlotterTable.columns.filledPercent',
  },
  json: {
    defaultMessage: 'JSON',
    id: 'BlotterTable.columns.json',
  },
  market: {
    defaultMessage: 'Market',
    id: 'BlotterTable.columns.market',
  },
  progress: {
    defaultMessage: 'Progress',
    id: 'BlotterTable.columns.progress',
  },
  role: {
    defaultMessage: 'Role',
    id: 'BlotterTable.columns.role',
  },
  status: {
    defaultMessage: 'Status',
    id: 'BlotterTable.columns.status',
  },
  strategy: {
    defaultMessage: 'Strategy',
    id: 'BlotterTable.columns.strategy',
  },
  transfer: {
    defaultMessage: 'Transfer',
    id: 'BlotterTable.columns.transfer',
  },
  user: {
    defaultMessage: 'User',
    id: 'BlotterTable.columns.user',
  },
  withdraw: {
    defaultMessage: 'Withdraw',
    id: 'BlotterTable.columns.withdraw',
  },
  timestamp: {
    defaultMessage: 'Timestamp',
    id: 'BlotterTable.columns.timestamp',
  },
  ordStatus: {
    defaultMessage: 'Order Status',
    id: 'BlotterTable.columns.ordStatus',
  },
  orderStatus: {
    defaultMessage: 'Order Status',
    id: 'BlotterTable.columns.orderStatus',
  },
  symbol: {
    defaultMessage: 'Symbol',
    id: 'BlotterTable.columns.symbol',
  },
  side: {
    defaultMessage: 'Side',
    id: 'BlotterTable.columns.side',
  },
  currency: {
    defaultMessage: 'Currency',
    id: 'BlotterTable.columns.currency',
  },
  asset: {
    defaultMessage: 'Asset',
    id: 'BlotterTable.columns.asset',
  },
  productType: {
    defaultMessage: 'Product Type',
    id: 'BlotterTable.columns.productType',
  },
  ordType: {
    defaultMessage: 'Order Type',
    id: 'BlotterTable.columns.ordType',
  },
  cumQty: {
    defaultMessage: 'Filled Qty',
    id: 'BlotterTable.columns.cumQty',
  },
  amountCurrency: {
    defaultMessage: 'Price Currency',
    id: 'BlotterTable.columns.amountCurrency',
  },
  cumFee: {
    defaultMessage: 'Fees',
    id: 'BlotterTable.columns.cumFee',
  },
  avgPx: {
    defaultMessage: 'Filled Price',
    id: 'BlotterTable.columns.avgPx',
  },
  EID: {
    defaultMessage: 'EID',
    id: 'BlotterTable.columns.EID',
  },
  OID: {
    defaultMessage: 'OID',
    id: 'BlotterTable.columns.OID',
  },
  transactionType: {
    defaultMessage: 'Type',
    id: 'BlotterTable.columns.transactionType',
  },
  addressType: {
    defaultMessage: 'Type',
    id: 'BlotterTable.columns.addressType',
  },
  addressRoutingType: {
    defaultMessage: 'Routing Type',
    id: 'BlotterTable.columns.addressRoutingType',
  },
  orderQty: {
    defaultMessage: 'Order Qty',
    id: 'BlotterTable.columns.orderQty',
  },
  orderID: {
    defaultMessage: 'Order ID',
    id: 'BlotterTable.columns.orderID',
  },
  tradeID: {
    defaultMessage: 'Trade ID',
    id: 'BlotterTable.columns.tradeID',
  },
  quoteID: {
    defaultMessage: 'Quote ID',
    id: 'BlotterTable.columns.quoteID',
  },
  externalID: {
    defaultMessage: 'External ID',
    id: 'BlotterTable.columns.externalID',
  },
  buy: {
    defaultMessage: 'Buy',
    id: 'BlotterTable.columns.buy',
  },
  sell: {
    defaultMessage: 'Sell',
    id: 'BlotterTable.columns.sell',
  },
  counterparty: {
    defaultMessage: 'Customer',
    id: 'BlotterTable.columns.counterparty',
  },
  tradeStatus: {
    defaultMessage: 'Status',
    id: 'BlotterTable.columns.tradeStatus',
  },
  submitTime: {
    defaultMessage: 'Date',
    id: 'BlotterTable.columns.submitTime',
  },
  filledQtyCurrency: {
    defaultMessage: 'Filled Qty Currency',
    id: 'BlotterTable.columns.filledQtyCurrency',
  },
  filledPriceCurrency: {
    defaultMessage: 'Filled Price Currency',
    id: 'BlotterTable.columns.filledPriceCurrency',
  },
  qtyCurrency: {
    defaultMessage: 'Qty Currency',
    id: 'BlotterTable.columns.qtyCurrency',
  },
  transactTime: {
    defaultMessage: 'Transact Time',
    id: 'BlotterTable.columns.transactTime',
  },
  price: {
    defaultMessage: 'Price',
    id: 'BlotterTable.columns.price',
  },
  longExposureLimit: {
    defaultMessage: 'Long Limit',
    id: 'BlotterTable.columns.longExposureLimit',
  },
  longExposure: {
    defaultMessage: 'Long Exposure',
    id: 'BlotterTable.columns.longExposure',
  },
  shortExposureLimit: {
    defaultMessage: 'Short Limit',
    id: 'BlotterTable.columns.shortExposureLimit',
  },
  shortExposure: {
    defaultMessage: 'Short Exposure',
    id: 'BlotterTable.columns.shortExposure',
  },
  addressID: {
    defaultMessage: 'Address ID',
    id: 'BlotterTable.columns.addressID',
  },
  customerUser: {
    defaultMessage: 'User',
    id: 'BlotterTable.columns.customerUser',
  },
  quantity: {
    defaultMessage: 'Quantity',
    id: 'BlotterTable.columns.quantity',
  },
  amount: {
    defaultMessage: 'Amount',
    id: 'BlotterTable.columns.amount',
  },
  availableAmount: {
    defaultMessage: 'Available Amount',
    id: 'BlotterTable.columns.availableAmount',
  },
  txHash: {
    defaultMessage: 'Tx Hash',
    id: 'BlotterTable.columns.txHash',
  },
  lastMismatchTime: {
    defaultMessage: 'Last Mismatch',
    id: 'BlotterTable.columns.lastMismatchTime',
  },
  reconWarning: {
    defaultMessage: 'Reconciliation Warning',
    id: 'BlotterTable.columns.reconWarning',
  },
  cumAmt: {
    defaultMessage: 'Cum Amt',
    id: 'BlotterTable.columns.cumAmt',
  },
  fee: {
    defaultMessage: 'Fee',
    id: 'BlotterTable.columns.fee',
  },
  transactionID: {
    defaultMessage: 'Transaction ID',
    id: 'BlotterTable.columns.transactionID',
  },
  customerAddressID: {
    defaultMessage: 'Customer Address ID',
    id: 'BlotterTable.columns.customerAddressID',
  },
  underlyingCrossSymbol: {
    defaultMessage: 'Underlying Cross Symbol',
    id: 'BlotterTable.columns.underlyingCrossSymbol',
  },
  minimumSize: {
    defaultMessage: 'Minimum Size',
    id: 'BlotterTable.columns.minimumSize',
  },
  maximumSize: {
    defaultMessage: 'Maximum Size',
    id: 'BlotterTable.columns.maximumSize',
  },
  sizeBuckets: {
    defaultMessage: 'Size Buckets',
    id: 'BlotterTable.columns.sizeBuckets',
  },
  startTime: {
    defaultMessage: 'Start Time',
    id: 'BlotterTable.columns.startTime',
  },
  endTime: {
    defaultMessage: 'End Time',
    id: 'BlotterTable.columns.endTime',
  },
  email: {
    defaultMessage: 'Email',
    id: 'BlotterTable.columns.email',
  },
  mode: {
    defaultMessage: 'Mode',
    id: 'BlotterTable.columns.mode',
  },
  warnThreshold: {
    defaultMessage: 'Warning Threshold',
    id: 'BlotterTable.columns.warnThreshold',
  },
  rejectThreshold: {
    defaultMessage: 'Reject Threshold',
    id: 'BlotterTable.columns.rejectThreshold',
  },
  thresholdCurrency: {
    defaultMessage: 'Threshold Currency',
    id: 'BlotterTable.columns.thresholdCurrency',
  },
  updateAction: {
    defaultMessage: 'Update Action',
    id: 'BlotterTable.columns.updateAction',
  },
  tradingLimitID: {
    defaultMessage: 'Trading Limit ID',
    id: 'BlotterTable.columns.tradingLimitID',
  },
  description: {
    defaultMessage: 'Description',
    id: 'BlotterTable.columns.description',
  },
  subAccount: {
    defaultMessage: 'Sub Account',
    id: 'BlotterTable.columns.subAccount',
  },
  avgCost: {
    defaultMessage: 'Avg Cost',
    id: 'BlotterTable.columns.avgCost',
  },
  outstandingBuy: {
    defaultMessage: 'Outstanding Buy',
    id: 'BlotterTable.columns.outstandingBuy',
  },
  outstandingSell: {
    defaultMessage: 'Outstanding Sell',
    id: 'BlotterTable.columns.outstandingSell',
  },
  delta: {
    defaultMessage: 'Delta',
    id: 'BlotterTable.columns.delta',
  },
  lastUpdateTime: {
    defaultMessage: 'Last Update Time',
    id: 'BlotterTable.columns.lastUpdateTime',
  },
  markPrice: {
    defaultMessage: 'Mark Price',
    id: 'BlotterTable.columns.markPrice',
  },
  directionalAmount: {
    defaultMessage: 'Directional Amount',
    id: 'BlotterTable.columns.directionalAmount',
  },
  marketTransferID: {
    defaultMessage: 'Market Transfer ID',
    id: 'BlotterTable.columns.marketTransferID',
  },
  transferID: {
    defaultMessage: 'Transfer ID',
    id: 'BlotterTable.columns.transferID',
  },
  marketDescription: {
    defaultMessage: 'Market Description',
    id: 'BlotterTable.columns.marketDescription',
  },
  marketTransferStatus: {
    defaultMessage: 'Market Transfer Status',
    id: 'BlotterTable.columns.marketTransferStatus',
  },
  fromAddress: {
    defaultMessage: 'From Address',
    id: 'BlotterTable.columns.fromAddress',
  },
  toAddress: {
    defaultMessage: 'To Address',
    id: 'BlotterTable.columns.toAddress',
  },
  reportID: {
    defaultMessage: 'Report ID',
    id: 'BlotterTable.columns.reportID',
  },
  warning: {
    defaultMessage: 'Warning',
    id: 'BlotterTable.columns.warning',
  },
  unifiedLiquidity: {
    defaultMessage: 'Unified Liquidity',
    id: 'BlotterTable.columns.unifiedLiquidity',
  },
  parentRFQID: {
    defaultMessage: 'Parent RFQ ID',
    id: 'BlotterTable.columns.parentRFQID',
  },
  priceProtection: {
    defaultMessage: 'Price Protection',
    id: 'BlotterTable.columns.priceProtection',
  },
  cxlRejReason: {
    defaultMessage: 'Cancel Reject Reason',
    id: 'BlotterTable.columns.cxlRejReason',
  },
  cancel: {
    defaultMessage: 'Cancel',
    id: 'BlotterTable.columns.cancel',
  },
  aggressorSide: {
    defaultMessage: 'Aggressor Side',
    id: 'BlotterTable.columns.aggressorSide',
  },
  RFQID: {
    defaultMessage: 'RFQID',
    id: 'BlotterTable.columns.RFQID',
  },
  allocation: {
    defaultMessage: 'Allocation',
    id: 'BlotterTable.columns.allocation',
  },
  sessionID: {
    defaultMessage: 'Session ID',
    id: 'BlotterTable.columns.sessionID',
  },
  expectedFillQty: {
    defaultMessage: 'Expected Fill Qty',
    id: 'BlotterTable.columns.expectedFillQty',
  },
  expectedFillPrice: {
    defaultMessage: 'Expected Fill Price',
    id: 'BlotterTable.columns.expectedFillPrice',
  },
  parameters: {
    defaultMessage: 'Parameters',
    id: 'BlotterTable.columns.parameters',
  },
  parentOrderID: {
    defaultMessage: 'Parent Order ID',
    id: 'BlotterTable.columns.parentOrderID',
  },
  revision: {
    defaultMessage: 'Revision',
    id: 'BlotterTable.columns.revision',
  },
  text: {
    defaultMessage: 'Text',
    id: 'BlotterTable.columns.text',
  },
  execID: {
    defaultMessage: 'Exec ID',
    id: 'BlotterTable.columns.execID',
  },
  decisionStatus: {
    defaultMessage: 'Decision Status',
    id: 'BlotterTable.columns.decisionStatus',
  },
  origClOrdID: {
    defaultMessage: 'Orig ClOrdID',
    id: 'BlotterTable.columns.origClOrdID',
  },
  timeInForce: {
    defaultMessage: 'Time In Force',
    id: 'BlotterTable.columns.timeInForce',
  },
  remainQty: {
    defaultMessage: 'Remaining Quantity',
    id: 'BlotterTable.columns.remainQty',
  },
  clOrdID: {
    defaultMessage: 'ClOrdID',
    id: 'BlotterTable.columns.clOrdID',
  },
  lastTradeTime: {
    defaultMessage: 'Last Trade Time',
    id: 'BlotterTable.columns.lastTradeTime',
  },
  group: {
    defaultMessage: 'Group',
    id: 'BlotterTable.columns.group',
  },
  tradedMarketAccounts: {
    defaultMessage: 'Traded Market Accounts',
    id: 'BlotterTable.columns.tradedMarketAccounts',
  },
  selectedMarketAccounts: {
    defaultMessage: 'Selected Market Accounts',
    id: 'BlotterTable.columns.selectedMarketAccounts',
  },
  riskStatus: {
    defaultMessage: 'Risk Status',
    id: 'BlotterTable.columns.riskStatus',
  },
  comments: {
    defaultMessage: 'Comments',
    id: 'BlotterTable.columns.comments',
  },
  connectionID: {
    defaultMessage: 'Connection ID',
    id: 'BlotterTable.columns.connectionID',
  },
  name: {
    defaultMessage: 'Name',
    id: 'BlotterTable.columns.name',
  },
  connectionType: {
    defaultMessage: 'Connection Type',
    id: 'BlotterTable.columns.connectionType',
  },
  beginString: {
    defaultMessage: 'Begin String',
    id: 'BlotterTable.columns.beginString',
  },
  senderCompID: {
    defaultMessage: 'Sender Comp ID',
    id: 'BlotterTable.columns.senderCompID',
  },
  targetCompID: {
    defaultMessage: 'Target Comp ID',
    id: 'BlotterTable.columns.targetCompID',
  },
  quoteStatus: {
    defaultMessage: 'Quote Status',
    id: 'BlotterTable.columns.quoteStatus',
  },
  vega: {
    defaultMessage: 'Vega',
    id: 'BlotterTable.columns.vega',
  },
  execType: {
    defaultMessage: 'Exec Type',
    id: 'BlotterTable.columns.execType',
  },
  expiration: {
    defaultMessage: 'Expiration',
    id: 'BlotterTable.columns.expiration',
  },
  rho: {
    defaultMessage: 'Rho',
    id: 'BlotterTable.columns.rho',
  },
  displayName: {
    defaultMessage: 'Display Name',
    id: 'BlotterTable.columns.displayName',
  },
  reduceOnly: {
    defaultMessage: 'Reduce Only',
    id: 'BlotterTable.columns.reduceOnly',
  },
  reduceFirst: {
    defaultMessage: 'Reduce First',
    id: 'BlotterTable.columns.reduceFirst',
  },
  direction: {
    defaultMessage: 'Direction',
    id: 'BlotterTable.columns.direction',
  },
  credentialID: {
    defaultMessage: 'Credential ID',
    id: 'BlotterTable.columns.credentialID',
  },
  positionAsset: {
    defaultMessage: 'Position Asset',
    id: 'BlotterTable.columns.positionAsset',
  },
  creditUsages: {
    defaultMessage: 'Credit Usage',
    id: 'BlotterTable.columns.creditUsages',
  },
  lastFee: {
    defaultMessage: 'Last Fee',
    id: 'BlotterTable.columns.lastFee',
  },
  limitID: {
    defaultMessage: 'Limit ID',
    id: 'BlotterTable.columns.limitID',
  },
  bestBidIv: {
    defaultMessage: 'Best Bid IV',
    id: 'BlotterTable.columns.bestBidIv',
  },
  bestOfferIv: {
    defaultMessage: 'Best Offer IV',
    id: 'BlotterTable.columns.bestOfferIv',
  },
  underlyingPrice: {
    defaultMessage: 'Underlying Price',
    id: 'BlotterTable.columns.underlyingPrice',
  },
  key: {
    defaultMessage: 'Key',
    id: 'BlotterTable.columns.key',
  },
  lastExecID: {
    defaultMessage: 'Last Exec ID',
    id: 'BlotterTable.columns.lastExecID',
  },
  minPriceIncrement: {
    defaultMessage: 'Min Price Increment',
    id: 'BlotterTable.columns.minPriceIncrement',
  },
  minSizeIncrement: {
    defaultMessage: 'Min Size Increment',
    id: 'BlotterTable.columns.minSizeIncrement',
  },
  maxPriceIncrement: {
    defaultMessage: 'Max Price Increment',
    id: 'BlotterTable.columns.maxPriceIncrement',
  },
  maxSizeIncrement: {
    defaultMessage: 'Max Size Increment',
    id: 'BlotterTable.columns.maxSizeIncrement',
  },
  liquidationPrice: {
    defaultMessage: 'Liquidation Price',
    id: 'BlotterTable.columns.liquidationPrice',
  },
  limit: {
    defaultMessage: 'Limit',
    id: 'BlotterTable.columns.limit',
  },
  value: {
    defaultMessage: 'Value',
    id: 'BlotterTable.columns.value',
  },
  lastActive: {
    defaultMessage: 'Last Active',
    id: 'BlotterTable.columns.lastActive',
  },
  label: {
    defaultMessage: 'Label',
    id: 'BlotterTable.columns.label',
  },
  executionStrategy: {
    defaultMessage: 'Execution Strategy',
    id: 'BlotterTable.columns.executionStrategy',
  },
  minNotionalThreshold: {
    defaultMessage: 'Min Notional Threshold',
    id: 'BlotterTable.columns.minNotionalThreshold',
  },
  maxNotionalThreshold: {
    defaultMessage: 'Max Notional Threshold',
    id: 'BlotterTable.columns.maxNotionalThreshold',
  },
  minQuantityThreshold: {
    defaultMessage: 'Min Qty Threshold',
    id: 'BlotterTable.columns.minQuantityThreshold',
  },
  maxQuantityThreshold: {
    defaultMessage: 'Max Qty Threshold',
    id: 'BlotterTable.columns.maxQuantityThreshold',
  },
  priority: {
    defaultMessage: 'Priority',
    id: 'BlotterTable.columns.priority',
  },
  type: {
    defaultMessage: 'Type',
    id: 'BlotterTable.columns.type',
  },
  ID: {
    defaultMessage: 'ID',
    id: 'BlotterTable.columns.ID',
  },
  typeDescription: {
    defaultMessage: 'Type Description',
    id: 'BlotterTable.columns.typeDescription',
  },
  organization: {
    defaultMessage: 'Organization',
    id: 'BlotterTable.columns.organization',
  },
  action: {
    defaultMessage: 'Action',
    id: 'BlotterTable.columns.action',
  },
  position: {
    defaultMessage: 'Position',
    id: 'BlotterTable.columns.position',
  },
  tradedPx: {
    defaultMessage: 'Traded Price',
    id: 'BlotterTable.columns.tradedPx',
  },
  tradedAmt: {
    defaultMessage: 'Traded Amount',
    id: 'BlotterTable.columns.tradedAmt',
  },
  origQuoteReqID: {
    defaultMessage: 'Orig Quote Req ID',
    id: 'BlotterTable.columns.origQuoteReqID',
  },
  internalText: {
    defaultMessage: 'Internal Text',
    id: 'BlotterTable.columns.internalText',
  },
  effectiveOfferSpread: {
    defaultMessage: 'Effective Offer Spread',
    id: 'BlotterTable.columns.effectiveOfferSpread',
  },
  effectiveBidSpread: {
    defaultMessage: 'Effective Bid Spread',
    id: 'BlotterTable.columns.effectiveBidSpread',
  },
  expectedHedgeOfferPx: {
    defaultMessage: 'Expected Hedge Offer Px',
    id: 'BlotterTable.columns.expectedHedgeOfferPx',
  },
  expectedHedgeBidPx: {
    defaultMessage: 'Expected Hedge Bid Px',
    id: 'BlotterTable.columns.expectedHedgeBidPx',
  },
  offerAmt: {
    defaultMessage: 'Offer Amount',
    id: 'BlotterTable.columns.offerAmt',
  },
  offerPx: {
    defaultMessage: 'Offer Price',
    id: 'BlotterTable.columns.offerPx',
  },
  validUntilTime: {
    defaultMessage: 'Valid Until Time',
    id: 'BlotterTable.columns.validUntilTime',
  },
  bidPx: {
    defaultMessage: 'Bid Price',
    id: 'BlotterTable.columns.bidPx',
  },
  bidAmt: {
    defaultMessage: 'Bid Amount',
    id: 'BlotterTable.columns.bidAmt',
  },
  tradedQty: {
    defaultMessage: 'Traded Quantity',
    id: 'BlotterTable.columns.tradedQty',
  },
  tradedSide: {
    defaultMessage: 'Traded Side',
    id: 'BlotterTable.columns.tradedSide',
  },
  quoteReqID: {
    defaultMessage: 'Quote Req ID',
    id: 'BlotterTable.columns.quoteReqID',
  },
  messageID: {
    defaultMessage: 'Message ID',
    id: 'BlotterTable.columns.messageID',
  },
  updateUser: {
    defaultMessage: 'Update User',
    id: 'BlotterTable.columns.updateUser',
  },
  tradeAction: {
    defaultMessage: 'Trade Action',
    id: 'BlotterTable.columns.tradeAction',
  },
  tradeSource: {
    defaultMessage: 'Trade Source',
    id: 'BlotterTable.columns.tradeSource',
  },
  clTradeID: {
    defaultMessage: 'Cl Trade ID',
    id: 'BlotterTable.columns.clTradeID',
  },
  pricingParameters: {
    defaultMessage: 'Pricing Parameters',
    id: 'BlotterTable.columns.pricingParameters',
  },
  ordRejReason: {
    defaultMessage: 'Order Reject Reason',
    id: 'BlotterTable.columns.ordRejReason',
  },
  adminFillPrice: {
    defaultMessage: 'Admin Fill Price',
    id: 'BlotterTable.columns.adminFillPrice',
  },
  leavesQty: {
    defaultMessage: 'Leaves Quantity',
    id: 'BlotterTable.columns.leavesQty',
  },
  lastQty: {
    defaultMessage: 'Last Quantity',
    id: 'BlotterTable.columns.lastQty',
  },
  lastPx: {
    defaultMessage: 'Last Price',
    id: 'BlotterTable.columns.lastPx',
  },
  lastAmt: {
    defaultMessage: 'Last Amount',
    id: 'BlotterTable.columns.lastAmt',
  },
  internalOrdRejReason: {
    defaultMessage: 'Internal Order Reject Reason',
    id: 'BlotterTable.columns.internalOrdRejReason',
  },
  dealerAction: {
    defaultMessage: 'Dealer Action',
    id: 'BlotterTable.columns.dealerAction',
  },
  cancelSessionID: {
    defaultMessage: 'Cancel Session ID',
    id: 'BlotterTable.columns.cancelSessionID',
  },
  allowedSlippage: {
    defaultMessage: 'Allowed Slippage',
    id: 'BlotterTable.columns.allowedSlippage',
  },
  displaySymbol: {
    defaultMessage: 'Display Symbol',
    id: 'BlotterTable.columns.displaySymbol',
  },
  sourceAccountID: {
    defaultMessage: 'Source Account ID',
    id: 'BlotterTable.columns.sourceAccountID',
  },
  gamma: {
    defaultMessage: 'Gamma',
    id: 'BlotterTable.columns.gamma',
  },
  creditUsageWarning: {
    defaultMessage: 'Credit Usage Warning',
    id: 'BlotterTable.columns.creditUsageWarning',
  },
  exposureDefinition: {
    defaultMessage: 'Exposure Definition',
    id: 'BlotterTable.columns.exposureDefinition',
  },
  marketAccountID: {
    defaultMessage: 'Market Account ID',
    id: 'BlotterTable.columns.marketAccountID',
  },
  theta: {
    defaultMessage: 'Theta',
    id: 'BlotterTable.columns.theta',
  },
  auth0ID: {
    defaultMessage: 'Auth0 ID',
    id: 'BlotterTable.columns.auth0ID',
  },
  vwapSlippage: {
    defaultMessage: 'VWAP Slippage',
    id: 'BlotterTable.columns.vwapSlippage',
  },
  arrivalSlippage: {
    defaultMessage: 'Arrival Slippage',
    id: 'BlotterTable.columns.arrivalSlippage',
  },
  avgAmount: {
    defaultMessage: 'Avg Amount',
    id: 'BlotterTable.columns.avgAmount',
  },
  totalVolume: {
    defaultMessage: 'Total Volume',
    id: 'BlotterTable.columns.totalVolume',
  },
  twapSlippage: {
    defaultMessage: 'TWAP Slippage',
    id: 'BlotterTable.columns.twapSlippage',
  },
  cancelRate: {
    defaultMessage: 'Cancel Rate',
    id: 'BlotterTable.columns.cancelRate',
  },
  fillRate: {
    defaultMessage: 'Fill Rate',
    id: 'BlotterTable.columns.fillRate',
  },
  rejectRate: {
    defaultMessage: 'Reject Rate',
    id: 'BlotterTable.columns.rejectRate',
  },
  minimumAmount: {
    defaultMessage: 'Minimum Amount',
    id: 'BlotterTable.columns.minimumAmount',
  },
  talosFeeCurrency: {
    defaultMessage: 'Talos Fee Currency',
    id: 'BlotterTable.columns.talosFeeCurrency',
  },
  talosFee: {
    defaultMessage: 'Talos Fee',
    id: 'BlotterTable.columns.talosFee',
  },
  allocID: {
    defaultMessage: 'Alloc ID',
    id: 'BlotterTable.columns.allocID',
  },
  dealtCurrency: {
    defaultMessage: 'Dealt Currency',
    id: 'BlotterTable.columns.dealtCurrency',
  },
  resolution: {
    defaultMessage: 'Resolution',
    id: 'BlotterTable.columns.resolution',
  },
  state: {
    defaultMessage: 'State',
    id: 'BlotterTable.columns.state',
  },
  positionSource: {
    defaultMessage: 'Position Source',
    id: 'BlotterTable.columns.positionSource',
  },
  rid: {
    defaultMessage: 'Rid',
    id: 'BlotterTable.columns.rid',
  },
  marketAccount: {
    defaultMessage: 'Market Account',
    id: 'BlotterTable.columns.marketAccount',
  },
  lastMarket: {
    defaultMessage: 'Last Market',
    id: 'BlotterTable.columns.lastMarket',
  },
  orderStartTime: {
    defaultMessage: 'Order Start Time',
    id: 'BlotterTable.columns.orderStartTime',
  },
  orderEndTime: {
    defaultMessage: 'Order End Time',
    id: 'BlotterTable.columns.orderEndTime',
  },
  participationRate: {
    defaultMessage: 'Participation Rate',
    id: 'BlotterTable.columns.participationRate',
  },
  ownership: {
    defaultMessage: 'Ownership',
    id: 'BlotterTable.columns.ownership',
  },
  ruleID: {
    defaultMessage: 'Rule ID',
    id: 'BlotterTable.columns.ruleID',
  },
  minIncrement: {
    defaultMessage: 'Min Increment',
    id: 'BlotterTable.columns.minIncrement',
  },
  defaultIncrement: {
    defaultMessage: 'Default Increment',
    id: 'BlotterTable.columns.defaultIncrement',
  },
  allInTier: {
    defaultMessage: 'All In Tier',
    id: 'BlotterTable.columns.allInTier',
  },
  feeTier: {
    defaultMessage: 'Fee Tier',
    id: 'BlotterTable.columns.feeTier',
  },
  symbolGroup: {
    defaultMessage: 'Symbol Group',
    id: 'BlotterTable.columns.symbolGroup',
  },
  rate: {
    defaultMessage: 'Rate',
    id: 'BlotterTable.columns.rate',
  },
  settleStatus: {
    defaultMessage: 'Settle Status',
    id: 'BlotterTable.columns.settleStatus',
  },
  priceCurrency: {
    defaultMessage: 'Price Currency',
    id: 'BlotterTable.columns.priceCurrency',
  },
  realizedPnL: {
    defaultMessage: 'Realized PnL',
    id: 'BlotterTable.columns.realizedPnL',
  },
  totalPnL: {
    defaultMessage: 'Total PnL',
    id: 'BlotterTable.columns.totalPnL',
  },
  cumFees: {
    defaultMessage: 'Cum Fees',
    id: 'BlotterTable.columns.cumFees',
  },
  feeCurrency: {
    defaultMessage: 'Fee Currency',
    id: 'BlotterTable.columns.feeCurrency',
  },
  pnLcurrency: {
    defaultMessage: 'PnL Currency',
    id: 'BlotterTable.columns.pnLcurrency',
  },
  deltaCurrency: {
    defaultMessage: 'Delta Currency',
    id: 'BlotterTable.columns.deltaCurrency',
  },
  avgCostCurrency: {
    defaultMessage: 'Avg Cost Currency',
    id: 'BlotterTable.columns.avgCostCurrency',
  },
  avgCostHomeCurrency: {
    defaultMessage: 'Avg Cost ({homeCurrency})',
    id: 'BlotterTable.columns.avgCostHomeCurrency',
  },
  unrealizedPnL: {
    defaultMessage: 'Unrealized PnL',
    id: 'BlotterTable.columns.unrealizedPnL',
  },
  unrealizedPnLHomeCurrency: {
    defaultMessage: 'Unrealized PnL ({homeCurrency})',
    id: 'BlotterTable.columns.unrealizedPnLHomeCurrency',
  },
  realizedPnLHomeCurrency: {
    defaultMessage: 'Realized PnL ({homeCurrency})',
    id: 'BlotterTable.columns.realizedPnLHomeCurrency',
  },
  totalPnLHomeCurrency: {
    defaultMessage: 'Total PnL ({homeCurrency})',
    id: 'BlotterTable.columns.totalPnLHomeCurrency',
  },
  deltaHomeCurrency: {
    defaultMessage: 'Delta ({homeCurrency})',
    id: 'BlotterTable.columns.deltaHomeCurrency',
  },
  amountHomeCurrency: {
    defaultMessage: 'Amount ({homeCurrency})',
    id: 'BlotterTable.columns.amountHomeCurrency',
  },
  markPriceHomeCurrency: {
    defaultMessage: 'Mark Price ({homeCurrency})',
    id: 'BlotterTable.columns.markPriceHomeCurrency',
  },
  feeMode: {
    defaultMessage: 'Fee Mode',
    id: 'BlotterTable.columns.feeMode',
  },
  sourceMarketAccount: {
    defaultMessage: 'Source Account',
    id: 'BlotterTable.columns.sourceMarketAccount',
  },
  destinationMarketAccount: {
    defaultMessage: 'Dest Account',
    id: 'BlotterTable.columns.destinationMarketAccount',
  },
});

export type ColumnHeaderMessageKey = keyof typeof messages;
