import { Box, Divider, Flex, Text, type BoxProps } from '@talos/kyoko';

/**
 * A component for section
 * ```
 * {title}----------
 * ```
 */
export function SectionTitle({ title, fontSize = 'fontSizeLarge', ...rest }: { title: string } & BoxProps) {
  return (
    <Flex gap="spacingDefault" {...rest}>
      <Text weight="bold" color="colorTextImportant" fontSize={fontSize} whiteSpace="nowrap">
        {title}
      </Text>
      <Box w="100%" alignSelf="center">
        <Divider />
      </Box>
    </Flex>
  );
}
