import { createSelector } from '@reduxjs/toolkit';
import { pick } from 'lodash';
import { useAppStateSelector } from 'providers/AppStateProvider';
import type { AppState } from 'providers/AppStateProvider/types';
import { portfolioViewLayoutSlice } from './portfolioViewLayoutSlice';

export const selectPortfolioFlexState = createSelector(
  (state: AppState) => state.portfolioViewLayout,
  portfolioFlexState => {
    return pick(portfolioFlexState, ['userLayoutMapForViewTab', 'view', 'selectedTab']);
  }
);
export const usePortfolioViewRouteStateSelector = () => {
  return useAppStateSelector(selectPortfolioFlexState);
};

/** retrieve view state from app-level redux */
export const usePortfolioViewStateSelector = () => {
  return useAppStateSelector((state: AppState) => state.portfolioViewLayout.viewState);
};

export const getPortfolioViewActions = (): (typeof portfolioViewLayoutSlice)['actions'] => {
  return portfolioViewLayoutSlice.actions;
};
