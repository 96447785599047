import {
  EMPTY_ARRAY,
  PresenceEnum,
  ProductTypeEnum,
  getScaleFromIncrement,
  type BaseField,
  type FieldData,
  type OrderStrategy,
  type PriceProtectionEnum,
  type Security,
  type StrategyLike,
} from '@talos/kyoko';
import { mapParameterToField } from '../SalesOrder/Strategies';

// Ideally we will have one initializeStrategyParams for every form but splitting Order form & Sales order form
// for now as both have different validations
//[ UI-4182] - Change Strategy Qty fields validation in order form
export const initializeStrategyParams = ({
  security,
  strategy,
  defaultPriceProtection,
  excludedParams = EMPTY_ARRAY,
  isModify,
}: {
  strategy: StrategyLike | OrderStrategy | undefined;
  security: Security | undefined;
  defaultPriceProtection: PriceProtectionEnum | undefined;
  excludedParams?: string[];
  isModify?: boolean;
}): { [key: string]: BaseField<FieldData, any> } => {
  const params = {};

  if (strategy) {
    strategy.Parameters
      // Only TopLevelHidden param that exists seem to be OrdType and only applicable on Market strategy -- and we can handle that manually
      .filter(param => param.Presence !== PresenceEnum.TopLevelHidden && param.Presence !== PresenceEnum.Hidden)
      .filter(param => !excludedParams.includes(param.Type))
      .forEach(param => {
        params[param.Name] = mapParameterToField(
          param,
          getScaleFromIncrement(security?.MinPriceIncrement),
          undefined, // [UI-4182] Skip NumericField scale validation as we validate all Qty fields in validateStrategyParams function in OrderSlice.ts
          defaultPriceProtection,
          isModify
        );
      });
  }

  return params;
};

export function getHedgingSecurities(security: Security | undefined, securitiesList: Security[]): Security[] {
  if (!security || security.ProductType !== ProductTypeEnum.Option) {
    return [];
  }
  return securitiesList.filter(
    s =>
      s.BaseCurrency === security.BaseCurrency &&
      s.QuoteCurrency === security.UnderlyingQuoteCurrency &&
      s.ProductType === ProductTypeEnum.PerpetualSwap
  );
}
