import { Meter, OrdStatusEnum, QuoteStatusEnum } from '@talos/kyoko';
import type { ICellRendererParams, RowNode } from 'ag-grid-community';
import Big from 'big.js';
import { isCareOrderRow, isOrderRow, isQuoteRow, type CareOrderBlotterEntity } from './types';

const pendingFixingQuoteStatuses = [QuoteStatusEnum.PendingFix];
const fixingAppliedQuoteStatuses = [QuoteStatusEnum.Filled];
const workingQuoteStatuses = [QuoteStatusEnum.Open, QuoteStatusEnum.PendingFill, QuoteStatusEnum.PendingNew];

const pendingFixingOrdStatuses = [OrdStatusEnum.Filled];
const fixingAppliedOrdStatuses = [OrdStatusEnum.DoneForDay];
const workingOrdStatuses = [
  OrdStatusEnum.New,
  OrdStatusEnum.PartiallyFilled,
  OrdStatusEnum.PendingNew,
  OrdStatusEnum.PendingCancel,
  OrdStatusEnum.PendingReplace,
  OrdStatusEnum.Replaced,
  OrdStatusEnum.Replaced,
];

export function childRowsToMeterBars(childRows: RowNode[] | null | undefined, totalQty: string) {
  if (childRows == null) {
    throw new Error('missing child rows');
  }
  const fixingApplied = {
    id: 'fixingApplied',
    value: Big(0),
    color: 'var(--colors-green-lighten)',
    appearance: 'filled',
  } as const;
  const pendingFixing = {
    id: 'pendingFixing',
    value: Big(0),
    color: 'var(--colors-blue-lighten)',
    appearance: 'filled',
  } as const;
  const working = {
    id: 'working',
    value: Big(0),
    color: 'var(--colors-blue-lighten)',
    appearance: 'dashed',
  } as const;

  for (const row of childRows) {
    let value: string | undefined;
    // Used as a mutable reference
    let target: { value: Big } | undefined;

    if (isQuoteRow(row.data)) {
      const quote = row.data;
      value = quote.OrderQty;
      if (pendingFixingQuoteStatuses.includes(quote.QuoteStatus)) {
        target = pendingFixing;
      } else if (fixingAppliedQuoteStatuses.includes(quote.QuoteStatus)) {
        target = fixingApplied;
      } else if (workingQuoteStatuses.includes(quote.QuoteStatus)) {
        target = working;
      }
    } else if (isOrderRow(row.data)) {
      const order = row.data;
      value = order.OrderQty;
      if (pendingFixingOrdStatuses.includes(order.OrdStatus)) {
        target = pendingFixing;
      } else if (fixingAppliedOrdStatuses.includes(order.OrdStatus)) {
        target = fixingApplied;
      } else if (workingOrdStatuses.includes(order.OrdStatus)) {
        target = working;
        continue;
      }
    }

    if (value == null || target == null) {
      continue;
    }

    target.value = target.value.plus(Big(value).div(totalQty));
  }

  return [fixingApplied, pendingFixing, working];
}

export function AgCareOrderProgress(params: ICellRendererParams<CareOrderBlotterEntity>) {
  if (!isCareOrderRow(params.data)) {
    throw new Error('expected CareOrderRow');
  }
  if (params.node?.id == null) {
    throw new Error('missing node id');
  }
  // Specifically use `childrenAfterGroup` here (instead of `childrenAfterFilter`)
  // as we don't want to exclude any rows that are hidden due to filtering
  const childRows = params.api.getRowNode(params.node.id)?.childrenAfterGroup;
  const bars = childRowsToMeterBars(childRows, params.data.OrderQty);
  return (
    <Meter label={params.valueFormatted ?? params.value}>
      {bars.map(bar =>
        bar.value.eq(0) ? null : (
          <Meter.Bar key={bar.id} width={bar.value.toNumber() * 100} color={bar.color} appearance={bar.appearance} />
        )
      )}
    </Meter>
  );
}
