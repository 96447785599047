import { ConnectionType, isTalosUser, ROLE, useMarketsContext } from '@talos/kyoko';
import { MULTILEG_MARKET } from 'providers/OMSContext.types';
import { OrgConfigurationKey, useOrgConfiguration } from 'providers/OrgConfigurationProvider';
import { useMemo } from 'react';
import { isUserRole } from './useRoleAuth';
import { useUser } from './useUser';

/**
 * A hook to retrieve current values of application feature flags.
 * Add logic inside this hook for each new feature flag definition.
 *
 * @returns Object with current values of all defined feature flags
 */
export function useFeatureFlag() {
  const user = useUser();
  const { getConfig } = useOrgConfiguration();
  const { isMarketOnline } = useMarketsContext();

  return useMemo(() => {
    return {
      enableCustomerTradingLimitsPerAccount: getConfig(OrgConfigurationKey.EnableCustomerTradingLimitsPerAccount, true),
      viewDealerAdminAndBuyingPowerMenuItems: getConfig(OrgConfigurationKey.ViewWhitelabelMenuItems, isTalosUser(user)),
      showCustomerExecutionRulesBlotter: getConfig(
        OrgConfigurationKey.ShowCustomerOrderAcceptanceRules,
        isTalosUser(user)
      ),
      showCustomerTiering: getConfig(OrgConfigurationKey.ShowCustomerTiering, false),
      enableCreditLimitsPerMarketAccount: getConfig(OrgConfigurationKey.EnableCreditLimitsPerMarketAccount, true),
      // Use the status of the multileg market to indicate if we should show it's configuration screen.
      showMultiLegSecmaster: getConfig(
        OrgConfigurationKey.ShowMultiLegSecmaster,
        isMarketOnline(MULTILEG_MARKET, ConnectionType.Orders)
      ),
      enableNewCredentials: getConfig(OrgConfigurationKey.EnableNewCredentials, true),
      showPositionsBlotterWarningColumn: getConfig(
        OrgConfigurationKey.ShowPositionsBlotterWarningColumn,
        isTalosUser(user)
      ),
      showSecurityMasterUnifiedLiquidityColumn: getConfig(
        OrgConfigurationKey.ShowSecurityMasterUnifiedLiquidityColumn,
        false
      ),
      showCustomerDepositAddressSelection: getConfig(OrgConfigurationKey.ShowCustomerDepositAddressSelection, false),
      enableMonitoringBlotters: getConfig(OrgConfigurationKey.EnableMonitoringBlotters, isTalosUser(user)),
      enableAccountSegregation: getConfig(OrgConfigurationKey.EnableAccountSegregation, false),
      enableAccountSegregationConfiguration: getConfig(
        OrgConfigurationKey.EnableAccountSegregationConfiguration,
        false
      ),
      enableMarketCredentials: getConfig(OrgConfigurationKey.EnableMarketCredentials, false),
      supportedStrategiesForPricingModes: getConfig(
        OrgConfigurationKey.SupportedStrategiesForPricingModes,
        'Limit,Iceberg'
      )
        .split(',')
        .map(strategy => strategy.trim())
        .filter(strategy => !!strategy), // guard against trailing commas leading to empty strings
      pricingRuleAllowedCrossMarkets: getConfig(
        OrgConfigurationKey.PricingRuleAllowedCrossMarkets,
        'internal,sucden,lmax_london'
      )
        .split(',')
        .map(market => market.trim())
        .filter(market => !!market), // guard against trailing commas leading to empty strings
      supportedLayoutArrangements: getConfig(OrgConfigurationKey.SupportedLayoutArrangements, '')
        .split(',')
        .map(layoutArrangement => layoutArrangement.trim())
        .filter(layoutArrangement => !!layoutArrangement), // guard against trailing commas leading to empty strings
      enableSpotPositionsBlotter: getConfig(OrgConfigurationKey.EnableSpotPositionsBlotter, isTalosUser(user)),
      enableBulkClosePosition: getConfig(OrgConfigurationKey.EnableBulkClosePosition, false),
      enableOptionTrading: getConfig(OrgConfigurationKey.EnableOptionTrading, isTalosUser(user)),
      // Portfolio settlement is available for Treasury users, and we also guard with an org config.
      // Both parts of the check are also just by default true available for talos users.
      enablePortfolioSettlement:
        (isUserRole(user, ROLE.TREASURY) || isTalosUser(user)) &&
        getConfig(OrgConfigurationKey.EnablePortfolioSettlement, isTalosUser(user)),
      enablePortfolioManagementSystem: getConfig(OrgConfigurationKey.EnablePortfolioManagementSystem, false),
      enableCalendarSpreads: getConfig(OrgConfigurationKey.EnableCalendarSpreads, isTalosUser(user)),
      enableDerivativesPositionsBlotterAdvanced: getConfig(
        OrgConfigurationKey.EnableDerivativesPositionsBlotterAdvanced,
        isTalosUser(user)
      ),
      enableCurrenciesFilterSelectAll: getConfig(OrgConfigurationKey.EnableCurrenciesFilterSelectAll, false),
      subAccountPositionsBlotterMode: getConfig(OrgConfigurationKey.SubAccountPositionsBlotterMode, 'Spot'), // "Spot", "Derivatives", "DerivativesAdvanced"
      enableCustomerAccountRestrictions: getConfig(
        OrgConfigurationKey.EnableCustomerAccountRestrictions,
        isTalosUser(user)
      ),
      enableReconMismatchesBlotter: getConfig(OrgConfigurationKey.EnableReconMismatchesBlotter, isTalosUser(user)),
      enableBuyingPowerMonitoringBlotter: getConfig(
        OrgConfigurationKey.EnableBuyingPowerMonitoringBlotter,
        isTalosUser(user)
      ),
      showAnalyticsMarketUptimesChart: getConfig(OrgConfigurationKey.ShowAnalyticsMarketUptimesChart, false),
      showBlotterPauseButton: getConfig(OrgConfigurationKey.ShowBlotterPauseButton, isTalosUser(user)),
      enablePriceGrid: getConfig(OrgConfigurationKey.EnablePriceGrid, isTalosUser(user)),
      enableHierarchicalSubAccountPositionsBlotter: getConfig(
        OrgConfigurationKey.EnableHierarchicalSubAccountPositionsBlotter,
        true
      ),
      homeCurrencyAdditionalCurrencies: getConfig(OrgConfigurationKey.HomeCurrencyAdditionalCurrencies, '')
        .split(',')
        .map(ccy => ccy.trim())
        .filter(ccy => !!ccy), // guard against trailing commas leading to empty strings
      customerBalanceShowForceWithdraw: getConfig(
        OrgConfigurationKey.CustomerBalanceShowForceWithdraw,
        isTalosUser(user)
      ),
      showSubAccountPositionPnLTagColumns: getConfig(
        OrgConfigurationKey.ShowSubAccountPositionPnLTagColumns,
        isTalosUser(user)
      ),
      enableTradeBestExecution: getConfig(OrgConfigurationKey.EnableTradeBestExecution, true),
      enableCFDs: getConfig(OrgConfigurationKey.EnableCFDs, isTalosUser(user)),
      enableTradingControls: getConfig(OrgConfigurationKey.EnableTradingControls, true),
      enableTradingControlsDefaultSettings: getConfig(OrgConfigurationKey.EnableTradingControlsDefaultSettings, false),
      showSupportChat: getConfig(OrgConfigurationKey.ShowSupportChat, true),
      enableAccountLedgerEventsPage: getConfig(OrgConfigurationKey.EnableAccountLedgerEventsPage, isTalosUser(user)),
      enableOrderFormSubAccountCreation: getConfig(
        OrgConfigurationKey.EnableOrderFormSubAccountCreation,
        isTalosUser(user)
      ),
      enablePerformancePageDerivatives: getConfig(
        OrgConfigurationKey.EnablePerformancePageDerivatives,
        isTalosUser(user)
      ),

      // ETF-related flags
      defaultSpreadToFixingRFQIndex: getConfig(
        OrgConfigurationKey.DefaultSpreadToFixingRFQIndex,
        JSON.stringify({
          'BTC-USD': ['BRRNY', 'XBX'],
          'ETH-USD': ['ECX', 'ETHUSD_NY'],
        })
      ),
      enableMakerMarketplaceETFRFQFlow: getConfig(OrgConfigurationKey.EnableMakerMarketplaceETFRFQFlow, false),
      enableTakerMarketplaceETFRFQFlow: getConfig(OrgConfigurationKey.EnableTakerMarketplaceETFRFQFlow, false),
      fixingPriceDialogMarkets: getConfig(OrgConfigurationKey.FixingPriceDialogMarkets, 'coinbase,kraken'),
      enableAutoHedging: getConfig(OrgConfigurationKey.EnableAutoHedging, false),
      enableOrderLinkInHedgeRule: getConfig(OrgConfigurationKey.EnableOrderLinkInHedgeRule, false),
      positionHedgerHomeCurrency: getConfig(
        OrgConfigurationKey.PositionHedgerHomeCurrency,
        DEFAULT_POSITION_HEDGER_HOME_CURRENCY
      ),
    };
  }, [getConfig, user, isMarketOnline]);
}

export const DEFAULT_POSITION_HEDGER_HOME_CURRENCY = 'USD';
