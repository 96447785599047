import type { TabComponentSpec } from 'components/FlexibleLayout/types';
import { RecentOrders } from 'containers/Blotters/Orders/RecentOrders';
import { RecentTrades } from 'containers/Blotters/Trades/RecentTrades';
import { RecentTransfers } from 'containers/Blotters/Transfers/RecentTransfers';
import { PORTFOLIO_DOCKVIEW_PANEL_GROUPS } from '../../../portfolioDashboardLayouts';

export type OperationsComponentType = (typeof operationsComponents)[number]['type'];
export const operationsComponents = [
  {
    group: PORTFOLIO_DOCKVIEW_PANEL_GROUPS['blotters'],
    type: 'ordersBlotter',
    label: 'Orders',
    renderer: () => <RecentOrders />,
  },
  {
    group: PORTFOLIO_DOCKVIEW_PANEL_GROUPS['blotters'],
    type: 'tradesBlotter',
    label: 'Trades',
    renderer: () => <RecentTrades />,
  },
  {
    group: PORTFOLIO_DOCKVIEW_PANEL_GROUPS['blotters'],
    type: 'transfersBlotter',
    label: 'Transfers',
    renderer: () => <RecentTransfers />,
  },
] as const satisfies TabComponentSpec[];
