import {
  useDynamicCallback,
  type FilterClause,
  type FilterableProperty,
  type UseFilterBuilderProps,
} from '@talos/kyoko';
import { useContextBlotterFilter } from 'providers/ContextBlotterFilterProvider';
import { createContext, useContext, useMemo, type PropsWithChildren } from 'react';
import type { BalancesFilter } from '../../../../types';
import { BalanceFilterKey, useBalancesFilterableProperties } from '../../../Blotters/BalancesV2/useBalancesV2Filter';
import { TreasuryManagementActionType } from '../TreasuryManagementReducer';
import { useTreasuryManagementContext } from './TreasuryManagementStateAndTabsProvider';

export const TreasuryManagementFiltersContext = createContext<TreasuryManagementFiltersContextProps | undefined>(
  undefined
);

export type TreasuryManagementFiltersContextProps = {
  filterableProperties: FilterableProperty[];
  onFilterClausesChanged: UseFilterBuilderProps['onFilterClausesChanged'];
};

export function useTreasuryManagementFilters() {
  const context = useContext(TreasuryManagementFiltersContext);
  if (context === undefined) {
    throw new Error(
      'Missing TreasuryManagementFiltersContext.Provider further up in the tree. Did you forget to add it?'
    );
  }
  return context;
}

/**
 * This provider allows anyone in the page to have access to the filterable properties for example
 */
export const TreasuryManagementFiltersProvider = function TreasuryManagementFiltersProvider({
  children,
}: PropsWithChildren) {
  const { dispatch } = useTreasuryManagementContext();
  const { filterableProperties } = useBalancesFilterableProperties();
  const { filterMutator } = useContextBlotterFilter<TreasuryManagementFiltersContextProps>();

  const onFilterClausesChanged: UseFilterBuilderProps['onFilterClausesChanged'] = useDynamicCallback(
    (filterClausesByPropertyKey: Map<string, FilterClause>) => {
      const newFilter: BalancesFilter = {
        Currencies: filterClausesByPropertyKey.get(BalanceFilterKey.Currencies)?.selections,
        Markets: filterClausesByPropertyKey.get(BalanceFilterKey.Markets)?.selections,
        MarketTypes: filterClausesByPropertyKey.get(BalanceFilterKey.MarketTypes)?.selections,
        Groups: filterClausesByPropertyKey.get(BalanceFilterKey.Groups)?.selections,
        MarketAccounts: filterClausesByPropertyKey.get(BalanceFilterKey.MarketAccounts)?.selections,
      };
      dispatch({
        type: TreasuryManagementActionType.FilterChange,
        payload: {
          filter: newFilter,
        },
      });
    }
  );

  const value = useMemo(() => {
    const result = filterMutator({
      filterableProperties,
      onFilterClausesChanged,
    });
    return result;
  }, [filterableProperties, onFilterClausesChanged, filterMutator]);

  return (
    <TreasuryManagementFiltersContext.Provider value={value}>{children}</TreasuryManagementFiltersContext.Provider>
  );
};
