import type { PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';
import { Box } from '../Core';
import { Icon, IconName } from '../Icons';

type IndicatorProps = {
  targetPercent: number | undefined;
  color: string;
  renderIndicator: boolean;
};

export const MeterTargetIndicatorWrapper = ({
  children,
  color,
  targetPercent,
  renderIndicator,
}: PropsWithChildren<IndicatorProps>) => {
  return (
    <MeterWrapper>
      <Indicator color={color} targetPercent={targetPercent} renderIndicator={renderIndicator}>
        <Icon icon={IconName.Play} size={8} />
        <Icon icon={IconName.Play} size={8} />
      </Indicator>
      {children}
    </MeterWrapper>
  );
};

/**
 * This is the indicator that shows the target position on the meter.
 * It is a thin line on the meter, and the pseudo-elements are two arrows pointing at it.
 */
const Indicator = styled(Box)<IndicatorProps>`
  z-index: 1;
  position: relative;

  i {
    display: none;
    visibility: hidden;
  }

  ${({ targetPercent, theme, color, renderIndicator }) =>
    renderIndicator &&
    targetPercent !== undefined &&
    css`
      position: absolute;
      top: -1px;
      bottom: 0px;
      transform: translateX(-100%);
      background-color: ${theme.colors.gray['040']};
      text-align: center;
      width: 1px;
      ${targetPercent <= 0
        ? css`
            right: calc(${Math.abs(targetPercent) * 100}% - 2%);
          `
        : css`
            left: ${Math.abs(targetPercent) * 100}%;
          `}

      i {
        display: unset;
        visibility: visible;
        color: ${color};
        position: absolute;
        text-align: center;
        left: -310%;
      }

      i:first-child {
        top: -5px;
        transform: rotate(90deg) translateY(0.4px);
      }
      i:last-child {
        top: 5px;
        transform: rotate(-90deg) translateY(-0.6px);
      }
    `}
`;

const MeterWrapper = styled(Box)`
  width: 100%;
  position: relative;
`;
