import { createAction, createReducer, type AnyAction } from '@reduxjs/toolkit';
import {
  BaseField,
  EMPTY_ARRAY,
  EMPTY_MAP,
  ModeEnum,
  NumericField,
  SelectorField,
  SubAccountTypeEnum,
  getAllChildrenOfSubAccount,
  type AggregationWithAccounts,
  type Currency,
  type IHedgeRule,
  type ISubaccount,
  type SubAccount,
} from '@talos/kyoko';
import type { WritableDraft } from 'immer';
import { every, isEqual, isUndefined, pick, uniq } from 'lodash';
import { useEffect } from 'react';
import { DEFAULT_POSITION_HEDGER_HOME_CURRENCY } from '../../hooks/useFeatureFlag';
import type { HedgeCommand } from '../AutoHedging/types';
import type {
  EditIHedgeRuleForm,
  HedgeRulePayload,
  InitReducerPayload,
  PositionAutoHedgingDrawerState,
  PositionAutoHedgingReferenceData,
  UpdateFieldPayload,
} from './types';
import { performValidation } from './validation';

export const HEDGECOMMAND_ARRAY = ['Kill'] satisfies HedgeCommand[];

const blankState = {
  form: {
    Asset: new SelectorField({ idProperty: 'Symbol', name: 'Asset', isRequired: true }),
    TargetAsset: new SelectorField({ idProperty: 'Symbol', name: 'Target Asset', isRequired: true }),
    TargetAccount: new SelectorField({ idProperty: 'SubaccountID', name: 'Subaccount', isRequired: true }),
    HedgeStrategy: new SelectorField({ idProperty: 'Name', name: 'Strategy', isRequired: true }),
    LongPositionLimit: new NumericField({ name: 'Long Threshold', isRequired: true }),
    ShortPositionLimit: new NumericField({ name: 'Short Threshold', isRequired: true }),
    TargetPosition: new NumericField({ name: 'Target Position', isRequired: true }),
    AccountID: new SelectorField({ idProperty: 'SubaccountID', name: 'Account', isRequired: true }),
    HedgeAggregation: new SelectorField<AggregationWithAccounts>({
      idProperty: 'Name',
      name: 'Aggregation',
      isRequired: true,
    }),
  },
  command: {
    mode: ModeEnum.Disabled,
  },
  hedgeRuleBeingChanged: null,
  referenceData: {
    currencies: EMPTY_ARRAY,
    currenciesBySymbol: EMPTY_MAP,
    strategies: EMPTY_ARRAY,
    subaccounts: EMPTY_ARRAY,
    aggregations: EMPTY_ARRAY,
    rollupMembershipsByParentChild: EMPTY_MAP,
    spotTradingPairsByBaseCurrency: EMPTY_MAP,
    spotTradingPairsByQuoteCurrency: EMPTY_MAP,
    positionHedgerHomeCurrency: DEFAULT_POSITION_HEDGER_HOME_CURRENCY,
  },
} satisfies PositionAutoHedgingDrawerState;

export const getInitialState = (payload?: InitReducerPayload): PositionAutoHedgingDrawerState => {
  if (!payload) {
    return blankState;
  }
  const {
    hedgeRule,
    currencies = EMPTY_ARRAY,
    strategies = EMPTY_ARRAY,
    subaccounts = EMPTY_ARRAY,
    aggregations = EMPTY_ARRAY,
    rollupMembershipsByParentChild = EMPTY_MAP,
    spotTradingPairsByBaseCurrency = EMPTY_MAP,
    spotTradingPairsByQuoteCurrency = EMPTY_MAP,
    currenciesBySymbol = EMPTY_MAP,
    positionHedgerHomeCurrency = DEFAULT_POSITION_HEDGER_HOME_CURRENCY,
  } = payload;
  const {
    Asset,
    TargetAsset,
    TargetAccount,
    HedgeStrategy,
    LongPositionLimit,
    ShortPositionLimit,
    TargetPosition,
    AccountID,
    HedgeAggregation,
  } = blankState.form;
  const hedgeStrategyField = HedgeStrategy.updateAvailableItems(strategies).updateValueFromID(hedgeRule?.HedgeStrategy);
  const longPositionLimitField = LongPositionLimit.updateValue(hedgeRule?.LongPositionLimit);
  const shortPositionLimitField = ShortPositionLimit.updateValue(hedgeRule?.ShortPositionLimit);
  const targetPositionField = TargetPosition.updateValue(hedgeRule?.TargetPosition);
  const hedgeAggregationField = HedgeAggregation.updateAvailableItems(aggregations).updateValueFromID(
    hedgeRule?.HedgeAggregation
  );

  const { assetField, targetAssetField } = getAssetAndTargetAsset({
    Asset,
    TargetAsset,
    referenceData: {
      spotTradingPairsByBaseCurrency,
      spotTradingPairsByQuoteCurrency,
      currenciesBySymbol,
      currencies,
      positionHedgerHomeCurrency,
    },
    newAsset: hedgeRule?.Asset,
    newTargetAsset: hedgeRule?.TargetAsset,
  });

  const { accountField, targetAccountField } = getAccountAndTargetAccount({
    AccountID,
    TargetAccount,
    referenceData: {
      subaccounts,
      rollupMembershipsByParentChild,
    },
    newAccountID: hedgeRule?.AccountID,
    newTargetAccountID: hedgeRule?.TargetAccountID,
  });

  return {
    form: {
      Asset: assetField.setTouched(false).setDisabled(!!hedgeRule),
      TargetAsset: targetAssetField.setTouched(false),
      TargetAccount: targetAccountField.setTouched(false),
      HedgeStrategy: hedgeStrategyField.setTouched(false),
      LongPositionLimit: longPositionLimitField.setTouched(false),
      ShortPositionLimit: shortPositionLimitField.setTouched(false),
      TargetPosition: targetPositionField.setTouched(false),
      AccountID: accountField.setTouched(false).setDisabled(!!hedgeRule),
      HedgeAggregation: hedgeAggregationField.setTouched(false),
    },
    command: {
      mode: hedgeRule?.Mode ?? ModeEnum.Disabled,
    },
    hedgeRuleBeingChanged: hedgeRule ?? null,
    referenceData: {
      currencies,
      currenciesBySymbol,
      strategies,
      subaccounts,
      aggregations,
      rollupMembershipsByParentChild,
      spotTradingPairsByBaseCurrency,
      spotTradingPairsByQuoteCurrency,
      positionHedgerHomeCurrency,
    },
  };
};

export const setHedgeRule = createAction<IHedgeRule>('setHedgeRule');
export const resetForm = createAction('resetForm');
export const updateField = createAction<UpdateFieldPayload>('updateField');
export const updateMode = createAction<ModeEnum>('updateMode');
export const updateHedgeCommand = createAction<(typeof HEDGECOMMAND_ARRAY)[number] | undefined>('updateHedgeCommand');
export const init = createAction<InitReducerPayload>('init');
export const touchAll = createAction('touchAll');
export const updateDependencies = createAction<PositionAutoHedgingReferenceData>('updateDependencies');

export const reducer = createReducer<PositionAutoHedgingDrawerState>(blankState, builder => {
  return builder
    .addCase(init, (state, { payload }) => {
      return getInitialState(payload);
    })
    .addCase(resetForm, state => {
      Object.keys(state.form).forEach(key => {
        if (state.form[key] instanceof BaseField) {
          state.form[key] = state.form[key].updateValue(undefined).setDisabled(false).setTouched(false);
        }
      });

      state.command = {
        mode: ModeEnum.Enabled,
      };
      state.hedgeRuleBeingChanged = undefined;

      return state;
    })
    .addCase(setHedgeRule, (state, { payload: hedgeRule }) => {
      handleChangeAccountID({
        state,
        newAccountID: hedgeRule.AccountID,
        newTargetAccountID: hedgeRule.TargetAccountID,
      });
      handleChangeAsset({
        state,
        newAssetSymbol: hedgeRule.Asset,
        newTargetAssetSymbol: hedgeRule.TargetAsset,
      });

      // These are disabled when modifying a hedge rule.
      state.form.AccountID = state.form.AccountID.setDisabled(!!hedgeRule);
      state.form.Asset = state.form.Asset.setDisabled(!!hedgeRule);

      state.form.HedgeStrategy = state.form.HedgeStrategy.updateValueFromID(hedgeRule?.HedgeStrategy);
      state.form.LongPositionLimit = state.form.LongPositionLimit.updateValue(hedgeRule?.LongPositionLimit);
      state.form.ShortPositionLimit = state.form.ShortPositionLimit.updateValue(hedgeRule?.ShortPositionLimit);
      state.form.TargetPosition = state.form.TargetPosition.updateValue(hedgeRule?.TargetPosition);
      state.form.HedgeAggregation = state.form.HedgeAggregation.updateValueFromID(hedgeRule?.HedgeAggregation);
      state.hedgeRuleBeingChanged = hedgeRule;

      state.command = {
        mode: hedgeRule?.Mode ?? ModeEnum.Disabled,
      };
    })
    .addCase(touchAll, state => {
      Object.keys(state.form).forEach(key => {
        if (state.form[key] instanceof BaseField) {
          state.form[key] = state.form[key].setTouched(true);
        }
      });
      return state;
    })
    .addCase(updateField, (state, action) => {
      const field = action.payload.key;
      switch (field) {
        case 'TargetAccount': {
          state.form.TargetAccount = state.form.TargetAccount.updateValue(action.payload.value);
          break;
        }
        case 'ShortPositionLimit': {
          state.form.ShortPositionLimit = state.form.ShortPositionLimit.updateValue(action.payload.value);
          state.form.TargetPosition = state.form.TargetPosition.setTouched(true);
          state.form.LongPositionLimit = state.form.LongPositionLimit.setTouched(true);
          state.form.ShortPositionLimit = state.form.ShortPositionLimit.setTouched(true);
          break;
        }
        case 'TargetPosition': {
          state.form.TargetPosition = state.form.TargetPosition.updateValue(action.payload.value);
          state.form.TargetPosition = state.form.TargetPosition.setTouched(true);
          state.form.LongPositionLimit = state.form.LongPositionLimit.setTouched(true);
          state.form.ShortPositionLimit = state.form.ShortPositionLimit.setTouched(true);
          break;
        }
        case 'LongPositionLimit': {
          state.form.LongPositionLimit = state.form.LongPositionLimit.updateValue(action.payload.value);
          state.form.TargetPosition = state.form.TargetPosition.setTouched(true);
          state.form.LongPositionLimit = state.form.LongPositionLimit.setTouched(true);
          state.form.ShortPositionLimit = state.form.ShortPositionLimit.setTouched(true);
          break;
        }
        case 'TargetAsset': {
          state.form.TargetAsset = state.form.TargetAsset.updateValue(action.payload.value);
          break;
        }
        case 'Asset': {
          state.form.Asset = state.form.Asset.updateValue(action.payload.value);
          // whenever this changes, update the available items of TargetAsset
          handleChangeAsset({
            state,
            newAssetSymbol: action.payload.value?.Symbol,
            newTargetAssetSymbol: state.form.TargetAsset.value?.Symbol,
          });
          break;
        }
        case 'HedgeStrategy': {
          state.form.HedgeStrategy = state.form.HedgeStrategy.updateValue(action.payload.value);
          break;
        }
        case 'AccountID': {
          state.form.AccountID = state.form.AccountID.updateValue(action.payload.value);
          handleChangeAccountID({ state, newAccountID: action.payload.value?.SubaccountID });
          break;
        }
        case 'HedgeAggregation': {
          state.form.HedgeAggregation = state.form.HedgeAggregation.updateValue(action.payload.value);
          break;
        }
        default: {
          const _typecheck: never = field;
        }
      }

      performValidation(state);

      return state;
    })
    .addCase(updateMode, (state, action) => {
      state.command.mode = action.payload;
      return state;
    })
    .addCase(updateDependencies, (state, action) => {
      const { accountField, targetAccountField } = getAccountAndTargetAccount({
        AccountID: state.form.AccountID,
        TargetAccount: state.form.TargetAccount,
        newAccountID: state.form.AccountID.value?.SubaccountID,
        newTargetAccountID: state.form.TargetAccount.value?.SubaccountID,
        referenceData: action.payload,
      });
      state.form.TargetAccount = targetAccountField.setTouched(false);
      state.form.AccountID = accountField.setTouched(false);

      const { assetField, targetAssetField } = getAssetAndTargetAsset({
        Asset: state.form.Asset,
        TargetAsset: state.form.TargetAsset,
        newAsset: state.form.Asset.value?.Symbol,
        newTargetAsset: state.form.TargetAsset.value?.Symbol,
        referenceData: action.payload,
      });

      state.form.Asset = assetField.setTouched(false);
      state.form.TargetAsset = targetAssetField.setTouched(false);

      state.form.HedgeAggregation = state.form.HedgeAggregation.updateAvailableItems(action.payload.aggregations);
      state.form.HedgeStrategy = state.form.HedgeStrategy.updateAvailableItems(action.payload.strategies, {
        keepIsTouched: true,
        preventDefaultToValue: true,
      });

      performValidation(state);

      return state;
    });
});

/**
 * Calls and applies getAssetAndTargetAsset
 **/
function handleChangeAsset({
  state,
  newAssetSymbol,
  newTargetAssetSymbol,
}: {
  state: WritableDraft<PositionAutoHedgingDrawerState>;
  /** rollup */
  newAssetSymbol?: Currency['Symbol'];
  /** book */
  newTargetAssetSymbol?: Currency['Symbol'];
}) {
  const { assetField, targetAssetField } = getAssetAndTargetAsset({
    referenceData: state.referenceData,
    Asset: state.form.Asset,
    TargetAsset: state.form.TargetAsset,
    newAsset: newAssetSymbol,
    newTargetAsset: newTargetAssetSymbol,
  });
  state.form.Asset = assetField;
  state.form.TargetAsset = targetAssetField;
}

/**
 * Based on the asset, the target asset available items are updated to be possible pairs.
 */
function getAssetAndTargetAsset({
  Asset,
  TargetAsset,
  referenceData,
  newAsset,
  newTargetAsset,
}: {
  Asset: WritableDraft<SelectorField<Currency>>;
  TargetAsset: WritableDraft<SelectorField<Currency>>;
  referenceData: Pick<
    PositionAutoHedgingReferenceData,
    | 'currencies'
    | 'spotTradingPairsByBaseCurrency'
    | 'spotTradingPairsByQuoteCurrency'
    | 'currenciesBySymbol'
    | 'positionHedgerHomeCurrency'
  >;
  newAsset: Currency['Symbol'] | undefined;
  newTargetAsset: Currency['Symbol'] | undefined;
}): { targetAssetField: EditIHedgeRuleForm['TargetAsset']; assetField: EditIHedgeRuleForm['Asset'] } {
  const assetKey = newAsset ?? Asset.value?.Symbol;

  const assetField = Asset.updateAvailableItems(
    referenceData.currencies.filter(c => c.Symbol !== referenceData.positionHedgerHomeCurrency)
  ).updateValueFromID(assetKey);
  const spotTradingPairsBase = (assetKey ? referenceData.spotTradingPairsByBaseCurrency.get(assetKey) : []) || [];
  const spotTradingPairsQuote = (assetKey ? referenceData.spotTradingPairsByQuoteCurrency.get(assetKey) : []) || [];
  // We also allow for the asset to be equal to the target asset. Then we hedge the position with 'PreconfiguredDefaultAsset'
  const selfTradingPair = assetKey ? [assetKey] : [];
  // We find all possible pairs that have the asset as the base or quote currency.
  const availableCurrencies = uniq(
    spotTradingPairsBase
      .map(pair => pair.QuoteCurrency)
      .concat(spotTradingPairsQuote.map(pair => pair.BaseCurrency))
      .concat(selfTradingPair)
  )
    .map(currency => referenceData.currenciesBySymbol.get(currency))
    .filter(Boolean) as Currency[];

  let targetAssetField = TargetAsset.updateAvailableItems(availableCurrencies).updateValueFromID(newTargetAsset, true);

  if (!assetField.value?.Symbol) {
    targetAssetField = targetAssetField.setDisabled(true);
  } else {
    targetAssetField = targetAssetField.setDisabled(false);
  }

  return { targetAssetField, assetField };
}

/**
 * AccountID is a rollup, targetAccount is a book, we narrow the selection of books based on the rollup.
 * Every time accountID change, we need to update the available books. This function updates relevant fields
 * appropriately based on the new accountID and targetAccountID.
 */
function getAccountAndTargetAccount({
  AccountID,
  TargetAccount,
  referenceData,
  newAccountID,
  newTargetAccountID,
}: {
  AccountID: WritableDraft<SelectorField<ISubaccount>>;
  TargetAccount: WritableDraft<SelectorField<ISubaccount>>;
  referenceData: Pick<PositionAutoHedgingReferenceData, 'rollupMembershipsByParentChild' | 'subaccounts'>;
  /** rollup */
  newAccountID: SubAccount['SubaccountID'] | undefined;
  /** book */
  newTargetAccountID: SubAccount['SubaccountID'] | undefined;
}) {
  const rollups = referenceData.subaccounts.filter(s => s.Type === SubAccountTypeEnum.Rollup);
  const accountField = AccountID.updateAvailableItems(rollups).updateValueFromID(newAccountID);

  let targetAccountField = TargetAccount;
  const effectiveNewAccountID = accountField.value?.SubaccountID;

  if (effectiveNewAccountID !== undefined) {
    const booksSet = getAllChildrenOfSubAccount(effectiveNewAccountID, referenceData.rollupMembershipsByParentChild);
    const books = referenceData.subaccounts.filter(s => booksSet.has(s.SubaccountID));
    targetAccountField = targetAccountField.updateAvailableItems(books).setDisabled(false);
  } else {
    targetAccountField = targetAccountField.setDisabled(true);
  }
  if (newTargetAccountID !== undefined) {
    targetAccountField = targetAccountField.updateValueFromID(newTargetAccountID);
  }

  return { targetAccountField, accountField };
}

/**
 * Calls and applies getAccountAndTargetAccount
 **/
function handleChangeAccountID({
  state,
  newAccountID,
  newTargetAccountID,
}: {
  state: WritableDraft<PositionAutoHedgingDrawerState>;
  /** rollup */
  newAccountID?: SubAccount['SubaccountID'];
  /** book */
  newTargetAccountID?: SubAccount['SubaccountID'];
}) {
  const { accountField, targetAccountField } = getAccountAndTargetAccount({
    AccountID: state.form.AccountID,
    TargetAccount: state.form.TargetAccount,
    referenceData: state.referenceData,
    newAccountID: newAccountID,
    newTargetAccountID: newTargetAccountID,
  });
  state.form.AccountID = accountField;
  state.form.TargetAccount = targetAccountField;
}
/**
 * Hook to update the dependencies of the drawer state for the PositionAutoHedgingDrawer.
 */
export function usePositionAutoHedgingDrawerDependencies({
  currencies,
  currenciesBySymbol,
  strategies,
  subaccounts,
  aggregations,
  rollupMembershipsByParentChild,
  spotTradingPairsByBaseCurrency,
  spotTradingPairsByQuoteCurrency,
  positionHedgerHomeCurrency,
  dispatch,
}: { dispatch: React.Dispatch<AnyAction> } & PositionAutoHedgingReferenceData) {
  useEffect(() => {
    dispatch(
      updateDependencies({
        currencies,
        currenciesBySymbol,
        strategies,
        subaccounts,
        aggregations,
        rollupMembershipsByParentChild,
        spotTradingPairsByBaseCurrency,
        spotTradingPairsByQuoteCurrency,
        positionHedgerHomeCurrency,
      })
    );
  }, [
    dispatch,
    currencies,
    currenciesBySymbol,
    strategies,
    subaccounts,
    aggregations,
    rollupMembershipsByParentChild,
    spotTradingPairsByBaseCurrency,
    spotTradingPairsByQuoteCurrency,
    positionHedgerHomeCurrency,
  ]);
}

/**
 * Select a JSON payload from the form state.
 */
export const selectFormValues = (form: EditIHedgeRuleForm): HedgeRulePayload => {
  return {
    Asset: form.Asset.value?.Symbol,
    TargetAsset: form.TargetAsset.value?.Symbol,
    TargetAccountID: form.TargetAccount.value?.SubaccountID,
    HedgeStrategy: form.HedgeStrategy.value?.Name,
    LongPositionLimit: form.LongPositionLimit.value,
    ShortPositionLimit: form.ShortPositionLimit.value,
    TargetPosition: form.TargetPosition.value,
    AccountID: form.AccountID.value?.SubaccountID,
    HedgeAggregation: form.HedgeAggregation.value?.Name,
  };
};

export const selectFormValuesValid = (state: HedgeRulePayload): state is Required<HedgeRulePayload> => {
  return every(Object.keys(state), key => !isUndefined(state[key]));
};

export const selectFormIsValid = (state: PositionAutoHedgingDrawerState) => {
  return !Object.keys(state.form).some(key => {
    const maybeField = state.form[key];
    if (maybeField instanceof BaseField) {
      return maybeField.hasError || maybeField.hasWarning;
    }
    return false;
  });
};

const FORM_KEYS = [
  'LongPositionLimit',
  'ShortPositionLimit',
  'TargetPosition',
  'Asset',
  'TargetAsset',
  'TargetAccount',
  'HedgeStrategy',
  'AccountID',
  'HedgeAggregation',
] satisfies (keyof EditIHedgeRuleForm)[];
type EnsureAllKeysUsed = Exclude<keyof EditIHedgeRuleForm, (typeof FORM_KEYS)[number]> extends never ? true : never;
// Ensure all keys are used in the form
const _allKeysUsedCheck: EnsureAllKeysUsed = true;

/**
 * Determine if the backend representation of the hedge rule being changed is out of sync
 * with the fields that initialized the form.
 */
export const selectIsHedgeRuleOutOfSync = ({
  hedgeRuleBeingChanged,
  latestHedgeRule,
}: {
  hedgeRuleBeingChanged: PositionAutoHedgingDrawerState['hedgeRuleBeingChanged'];
  latestHedgeRule: IHedgeRule | undefined;
}): boolean => {
  if (!hedgeRuleBeingChanged || !latestHedgeRule) {
    return false;
  }

  const form = pick(hedgeRuleBeingChanged, FORM_KEYS);
  const latestBackendRepresentation = pick(latestHedgeRule, FORM_KEYS);

  return !isEqual(form, latestBackendRepresentation);
};

export const selectFormHasChanged = (state: PositionAutoHedgingDrawerState) => {
  const { form, hedgeRuleBeingChanged } = state;
  if (!hedgeRuleBeingChanged) {
    return true;
  }
  const formValues = selectFormValues(form);
  const isChanged = Object.keys(formValues).some(key => formValues[key] !== hedgeRuleBeingChanged[key]);
  return isChanged;
};
