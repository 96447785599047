import { createDevTools } from '@redux-devtools/core';
import { DockMonitor } from '@redux-devtools/dock-monitor';
//import { InspectorMonitor } from '@redux-devtools/inspector-monitor';
import { SliderMonitor } from '@redux-devtools/slider-monitor';
import type { Store } from '@reduxjs/toolkit';
import { configureStore, type AnyAction, type Dispatch } from '@reduxjs/toolkit';
import type { Equal, Expect } from '@talos/kyoko/src/tests';
import {
  portfolioLayoutSaveMiddleware,
  portfolioViewLayoutSlice,
} from 'containers/Portfolio/PortfolioManagement/stateManagement/portfolioViewLayoutSlice';
import { enableMapSet, setAutoFreeze } from 'immer';
import { createContext } from 'react';
import {
  Provider,
  createDispatchHook,
  createSelectorHook,
  createStoreHook,
  type RootStateOrAny,
  type TypedUseSelectorHook,
} from 'react-redux';
import { ReplaySubject } from 'rxjs';
import { multilegComboSlice } from '../../components/MultilegCombo/MultilegComboSlice';
import { activeOrderCardsSlice } from '../../components/OMS/Cards/ActiveOrderCardsSlice';
import { careOrderSlice } from '../../components/OMS/CareOrderFormView/CareOrderSlice';
import { detailsDrawerSlice } from '../../components/OMS/Details/DetailsDrawerSlice';
import { manualOrderFillSlice } from '../../components/OMS/ManualOrderFillView/ManualOrderFillSlice';
import { manualRFQPricingSlice } from '../../components/OMS/ManualRFQPricingView/ManualRFQPricingSlice';
import { manualTradeSlice } from '../../components/OMS/ManualTradeView/ManualTradeSlice';
import { orderSlice } from '../../components/OMS/NewOrder/OrderSlice';
import { rfqSlice } from '../../components/OMS/NewRFQ/RFQSlice';
import { referenceDataSlice } from '../../components/OMS/OMSReferenceDataSlice';
import { OMSSlice } from '../../components/OMS/OMSSlice';
import { orderPresetSlice } from '../../components/OMS/Presets/OrderPresetsSlice';
import { salesOrderSlice } from '../../components/OMS/SalesOrder/SalesOrderSlice';
import { salesRFQSlice } from '../../components/OMS/SalesRFQView/SalesRFQSlice';
import type { AppState } from './types';

enableMapSet();

// By default we have immer autoFreeze disabled due to the fact NOT all of our Context maps are producing new instances each time with the parameter `newMapEachUpdate: true`.
// If we didn't disable it, then parts of the code base mutating these collections would throw an error.

// However by not freezing it forces immer to loop through each item in the collection instead of faster ref comparisons when doing equality checks which can cause performance issues.
// For collections that are however using `newMapEachUpdate: true`, we should manually freeze() them as seen in the reference data slice to avoid a performance issue such as UI-4080.

// Because this is an easy thing to forget to do, we should look at turning auto freeze back on and setting newMapEachUpdate to true for all context maps.
// The reason this hasn't been done yet however is due to worry over if we will be causing addition re-renders that we were not doing before, so this needs to be checked first.
setAutoFreeze(false);

export const AppStateContext = createContext<RootStateOrAny>(undefined);

export const useAppStateDispatch: () => Dispatch = createDispatchHook(AppStateContext);
export const useAppStateSelector: TypedUseSelectorHook<AppState> = createSelectorHook(AppStateContext);
export const useAppStateStore: () => Store<AppState> = createStoreHook(AppStateContext);

/**  This subject will allow us to create a pattern similar to redux-observable (powerful middleware leveraging rxjs)
 * With this we are able to observe all actions going into this store. Our services can now create custom observables that react to this
 * See OrderService.observeForMarginCost as example.
 *
 * IMPORTANT: See deprecation note with {@link appStateActionsStream}
 *
 * HACK: Setting replay subject to buffer=2 to avoid missing actions when the reader is watching this stream late,
 * as needed by SalesOrderService.
 */
const appStateActionsSubject: ReplaySubject<AnyAction> = new ReplaySubject(2);
/** Stream of App State actions
 *
 * @deprecated
 * USE WITH CARE: Make sure your reader is watching this stream early enough to not
 * miss any actions, since this is a hot observable.
 *
 * (Consider implementing a Redux Toolkit middleware so that the dispatch order doesn't matter, and the behavior
 * works directly against the state) */
export const appStateActionsStream = appStateActionsSubject.asObservable();

// It does nothing other than proxying the action into our Actions Subject
// Note: Subject receives action after the Store, again same as redux-observable
const customMiddleware = store => next => action => {
  next(action);
  appStateActionsSubject.next(action);
};

const isLocal = import.meta.env.VITE_AVA_ENV === 'local';
const isReduxDevToolsEnabled = isLocal && import.meta.env.VITE_AVA_REDUXDEVTOOLS === 'true';

const DevTools = createDevTools(
  <DockMonitor
    toggleVisibilityKey="ctrl-h"
    changePositionKey="ctrl-q"
    changeMonitorKey="ctrl-e"
    defaultIsVisible={false}
  >
    {/* InspectorMonitor package is bundled as CJS and Vite does not support CJS anymore - uncomment when needing locally */}
    {/* <InspectorMonitor theme="google" /> */}
    <SliderMonitor keyboardEnabled />
  </DockMonitor>
);

const DevToolsWrapper = ({ store }) => {
  return isLocal && isReduxDevToolsEnabled ? <DevTools store={store} /> : null;
};

export const getAppStateStore = () =>
  configureStore({
    reducer: {
      cards: activeOrderCardsSlice.reducer,
      careOrder: careOrderSlice.reducer,
      detailsDrawer: detailsDrawerSlice.reducer,
      manualOrderFill: manualOrderFillSlice.reducer,
      manualRFQPricing: manualRFQPricingSlice.reducer,
      manualTrade: manualTradeSlice.reducer,
      multilegCombo: multilegComboSlice.reducer,
      OMS: OMSSlice.reducer,
      order: orderSlice.reducer,
      portfolioViewLayout: portfolioViewLayoutSlice.reducer,
      presets: orderPresetSlice.reducer,
      referenceData: referenceDataSlice.reducer,
      rfq: rfqSlice.reducer,
      salesOrder: salesOrderSlice.reducer,
      salesRFQ: salesRFQSlice.reducer,
    },
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false,
      }).prepend(customMiddleware, portfolioLayoutSaveMiddleware.middleware),
    enhancers: getDefaultEnhancers => {
      const enhancers = getDefaultEnhancers();
      if (isReduxDevToolsEnabled) {
        enhancers.push(DevTools.instrument());
      }
      return enhancers;
    },

    // https://github.com/reduxjs/redux-devtools/issues/643
    // The chrome devtools runs in a separate process as the app/browser tab, this means that all actions and state
    // needs to be serialized in order for the extension to be able to read it
    // OMS have references to big payloads and state object meaning that the extension will run very slow / unusable
    // but fast as if we run it as a native component (DevTools)
    devTools: false,
  });

export const store = getAppStateStore();

export const AppStateProvider = ({ children }) => {
  return (
    <Provider context={AppStateContext} store={store}>
      <DevToolsWrapper store={store} />
      {children}
    </Provider>
  );
};

// TYPE LEVEL TESTS
type _Expect_Store_State_To_Match_RootState = Expect<Equal<AppState, ReturnType<typeof store.getState>>>;
