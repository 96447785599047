import {
  Accordion,
  AccordionBody,
  Box,
  Button,
  ButtonVariants,
  Divider,
  FilterBuilder,
  FilterBuilderClearAllButton,
  FilterBuilderToggleButton,
  FormControlSizes,
  HStack,
  IconName,
  useAccordionFilterBuilder,
  type FilterClause,
} from '@talos/kyoko';
import { useContextBlotterFilter } from 'providers/ContextBlotterFilterProvider';
import { useEffect } from 'react';
import { TopTabContentContainer } from '../../styles';
import { PerformanceActionType } from '../PerformanceReducer';
import { usePerformanceFilters, type PerformanceFiltersContextProps } from '../providers/PerformanceFiltersProvider';
import { usePerformanceInteractions } from '../providers/PerformanceInteractionsProvider';
import { usePerformanceContext, usePerformanceTabs } from '../providers/PerformanceStateAndTabsProvider';
import { InsightsToggleButton } from './InsightsToggleButton';
import { PeriodControl } from './PeriodControl';
import { ProductControl } from './ProductControl';
import { SubAccountControl } from './SubAccountControl';

interface PerformanceTopContentProps {
  initialFilterClauses: FilterClause[];
  initialFiltersOpen: boolean;
}

export const PerformanceTopContent = ({ initialFilterClauses, initialFiltersOpen }: PerformanceTopContentProps) => {
  const { dispatch } = usePerformanceContext();
  const { additionalFilterState } = useContextBlotterFilter<PerformanceFiltersContextProps>();

  const performanceFilters = usePerformanceFilters();
  const { filterableProperties, onFilterClausesChanged } = performanceFilters;
  const { cloneCurrentTab } = usePerformanceTabs();
  const { setOpenClause } = usePerformanceInteractions();

  const { accordion, filterBuilder, openClause } = useAccordionFilterBuilder({
    filterBuilderProps: {
      properties: filterableProperties ?? [],
      initialFilterClauses,
      onFilterClausesChanged,
    },
    accordionProps: { initialOpen: initialFiltersOpen },
  });

  useEffect(() => {
    setOpenClause(() => openClause);
  }, [openClause, setOpenClause]);

  useEffect(() => {
    dispatch({
      type: PerformanceActionType.SubAccountsChange,
      payload: {
        subAccounts: additionalFilterState?.SubAccounts ?? [],
      },
    });
  }, [additionalFilterState?.SubAccounts, dispatch]);

  return (
    <TopTabContentContainer>
      <HStack px="spacingDefault" gap="spacingDefault" justifyContent="space-between" w="100%" py="spacingDefault">
        <HStack gap="spacingDefault">
          {!additionalFilterState ? <SubAccountControl /> : null}
          <PeriodControl />
          <ProductControl />
        </HStack>
        <HStack gap="spacingDefault">
          <Button
            startIcon={IconName.Duplicate}
            variant={ButtonVariants.Default}
            size={FormControlSizes.Small}
            onClick={cloneCurrentTab}
          >
            Clone Tab
          </Button>
          <Divider orientation="vertical" />
          <FilterBuilderClearAllButton
            removeAllFilterClauses={filterBuilder.removeAllFilterClauses}
            disabled={filterBuilder.filterClauses.length === 0}
            size={FormControlSizes.Small}
          />
          <FilterBuilderToggleButton
            filterClauses={filterBuilder.filterClauses}
            isOpen={accordion.isOpen}
            onClick={() => accordion.toggle()}
            size={FormControlSizes.Small}
          />
          <Divider orientation="vertical" />
          <InsightsToggleButton />
        </HStack>
      </HStack>
      <Box w="100%">
        <Accordion {...accordion}>
          <AccordionBody p="0" px="spacingMedium" borderTopWhenOpened>
            <Box py="spacingMedium">
              <FilterBuilder {...filterBuilder} />
            </Box>
          </AccordionBody>
        </Accordion>
      </Box>
    </TopTabContentContainer>
  );
};
