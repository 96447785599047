import type { MarketAccount } from '../contexts';
import { TRANSFER } from '../tokens';
import { truncateBlockchainAddress } from '../utils';
import type { IMessageID, ITransfer, TransferStatusEnum } from './types';

export class Transfer {
  public static StreamName = TRANSFER;

  TransferID!: string;
  Market!: string;
  OwnerAccountID!: number;
  Amount!: string;
  Currency!: string;
  Status!: TransferStatusEnum;
  Timestamp!: string;
  TransactTime!: string;
  CredentialID!: number;
  ClientID!: string;
  User!: string;
  SubmitTime!: string;
  FromMarketAccountID!: number;
  ToMarketAccountID!: number;
  EffectiveDate?: string;
  ChainCurrencySymbol?: string;
  FeeAmount?: string;
  FeeCurrency?: string;
  OrgID!: number;
  ParentTransactionID?: string;
  MarketTransferStatus?: string;
  MarketTransferID?: string;
  OwnerSourceAccountID?: string;
  FromSourceAccountID?: string;
  ToSourceAccountID?: string;
  Description?: string;
  Text?: string;
  ReferenceData?: string;
  MarketDescription?: string;
  SourceData?: number;
  MessageID?: IMessageID;
  FromAddress?: string;
  ToAddress?: string;
  TxHash?: string;
  TxHashes?: string[];

  counterpartyAddressTruncated(address: string | undefined) {
    return address ? truncateBlockchainAddress(address) : undefined;
  }

  getAccountDisplay(marketAccountsByID: Map<number, MarketAccount> | undefined, type: 'source' | 'destination') {
    let accountID: number | undefined;
    let source: string | undefined;
    let address: string | undefined;
    switch (type) {
      case 'source':
        accountID = this.FromMarketAccountID;
        source = this.FromSourceAccountID;
        address = this.FromAddress;
        break;
      case 'destination':
        accountID = this.ToMarketAccountID;
        source = this.ToSourceAccountID;
        address = this.ToAddress;
        break;
    }

    if (accountID && accountID !== 0) {
      const marketAccount = marketAccountsByID?.get(accountID);
      return (marketAccount ? marketAccount.DisplayName ?? marketAccount.Name : undefined) ?? `ID: ${accountID}`;
    }
    return source ? `External ID: ${source}` : this.counterpartyAddressTruncated(address) ?? 'External ID: unknown';
  }

  /** Whether or not this transfer represents a reduction in the user's balance */
  get isTransferOutgoing() {
    return this.OwnerAccountID === this.FromMarketAccountID;
  }

  get rowID() {
    return this.TransferID;
  }

  constructor(data: Transfer | ITransfer) {
    Object.assign(this, data);
  }
}
