import {
  Alert,
  AlertVariants,
  Box,
  Button,
  FormControlSizes,
  HedgeCommandStatusEnum,
  HedgeControlStatusEnum,
  NotificationVariants,
  type IHedgeRule,
  type ToastProps,
} from '@talos/kyoko';
import { useCallback } from 'react';
import { useHedgeRuleCommandMutation } from '../../containers/Trading/Markets/PositionAutoHedgingControls/queries';
import type { IHedgePositionStatusRow } from '../../providers/HedgePositionStatusProvider';

export function PositionAutoHedgingStatus({
  hedgePositionStatus,
  hedgeRule,
  addToast,
}: {
  hedgePositionStatus: IHedgePositionStatusRow;
  hedgeRule: IHedgeRule;

  addToast: (toast: ToastProps) => void;
}) {
  const ruleDisabled = hedgePositionStatus.HedgeControlStatus === HedgeControlStatusEnum.Disabled;

  const sendHedgeCommandMutation = useHedgeRuleCommandMutation();

  const handleClickButton = useCallback(() => {
    sendHedgeCommandMutation
      .mutateAsync({
        Command: ruleDisabled ? 'Activate' : 'Kill',
        HedgeRuleID: hedgeRule.HedgeRuleID,
      })
      .then(res => {
        if (res.Status === HedgeCommandStatusEnum.Success) {
          addToast({
            text: `Successfully ${ruleDisabled ? 'enabled' : 'disabled'} rule.`,
            variant: NotificationVariants.Positive,
          });
        } else {
          addToast({
            text: `Failed to ${ruleDisabled ? 'enable' : 'disable'} rule.\n${res.Error}`,
            variant: NotificationVariants.Negative,
          });
        }
      })
      .catch(() => {
        addToast({
          text: `Failed to ${ruleDisabled ? 'enable' : 'disable'} rule.`,
          variant: NotificationVariants.Negative,
        });
      });
  }, [addToast, hedgeRule.HedgeRuleID, ruleDisabled, sendHedgeCommandMutation]);

  return (
    <Alert
      variant={ruleDisabled ? AlertVariants.Negative : AlertVariants.Positive}
      dismissable={false}
      alignItems="center"
      p="spacingDefault"
    >
      Rule is currently {ruleDisabled ? 'disabled' : 'enabled'}.
      <Box ml="auto">
        <Button
          data-testid="toggle-hedgerule"
          loading={sendHedgeCommandMutation.isPending}
          onClick={handleClickButton}
          size={FormControlSizes.Small}
        >
          {ruleDisabled ? 'Enable' : 'Disable'}
        </Button>
      </Box>
    </Alert>
  );
}
