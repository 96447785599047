import Big, { type BigSource } from 'big.js';
import type { IHedgePositionStatus } from '../../types';
import { bigMin, type RequiredProperties, toBig, toBigWithDefault } from '../../utils';

export type PositionThresholdMeterValue = Pick<
  IHedgePositionStatus,
  | 'CurrentPosition'
  | 'TargetPosition'
  | 'ShortPositionLimit'
  | 'LongPositionLimit'
  | 'TargetAsset'
  | 'HedgeControlStatus'
>;

export const isPositionThresholdMeterValue = (
  value: PositionThresholdMeterValue
): value is RequiredProperties<PositionThresholdMeterValue, keyof PositionThresholdMeterValue> => {
  return (
    typeof value.CurrentPosition === 'string' &&
    typeof value.TargetPosition === 'string' &&
    typeof value.ShortPositionLimit === 'string' &&
    typeof value.LongPositionLimit === 'string' &&
    typeof value.TargetAsset === 'string'
  );
};

export type PositionThresholdMeterValuesArgs = Pick<
  RequiredProperties<PositionThresholdMeterValue, keyof PositionThresholdMeterValue>,
  'TargetPosition' | 'ShortPositionLimit' | 'LongPositionLimit' | 'CurrentPosition'
>;

/**
 * Util function for rendering the position threshold meter.
 * It calculates the correct target percent, (where the indicator will be placed)
 * and the correct current percent (where the current position is, i.e. the width of the bar in the meter).
 */
export function getPositionThresholdMeterValues(value: PositionThresholdMeterValuesArgs) {
  const targetPosition = value.TargetPosition;
  const shortLimit = toBigWithDefault(value.ShortPositionLimit, 0);
  const longLimit = toBigWithDefault(value.LongPositionLimit, 0);
  const currentPosition = toBigWithDefault(value.CurrentPosition, 0);

  const targetOffset = Big(currentPosition).abs();
  const positionLessThanZero = !!toBig(currentPosition)?.lt(0);
  const positionGreaterThanZero = !!toBig(currentPosition)?.gt(0);

  const bigTargetPosition = Big(targetPosition);

  // worried about division with zero? cannot happen, if target limit is less than zero, so must the short limit
  const targetPercent = bigTargetPosition.lt(0) ? bigTargetPosition.div(shortLimit) : bigTargetPosition.div(longLimit);

  let currentPercent: Big | BigSource = 0;
  if (positionLessThanZero) {
    if (shortLimit.eq(0)) {
      // if the short limit is zero, the current percent is -1 or less, set it to -1
      currentPercent = -1;
    } else {
      currentPercent = Big(bigMin(targetOffset.div(shortLimit), 1) ?? 0).mul(-1);
    }
  } else if (positionGreaterThanZero) {
    if (longLimit.eq(0)) {
      // if the long limit is zero, the current percent is 1 or greater, set it to 1
      currentPercent = 1;
    } else {
      currentPercent = bigMin(targetOffset.div(longLimit), 1) ?? 0;
    }
  }

  return {
    currentPercent: Number(currentPercent.toFixed(2)),
    targetPercent: Number(targetPercent.toFixed(2)),
  };
}
