import { Button, ButtonVariants, Flex, PRINCIPAL_MAIN_HEADER_HEIGHT, setAlpha } from '@talos/kyoko';
import styled from 'styled-components';

export const HeaderWrapper = styled(Flex)`
  @media print {
    display: none;
  }
`;
HeaderWrapper.defaultProps = {
  h: `${PRINCIPAL_MAIN_HEADER_HEIGHT}px`,
  background: 'colors.gray.main',
  w: '100%',
  flex: `0 0 ${PRINCIPAL_MAIN_HEADER_HEIGHT}px`,
  overflow: 'hidden',
};

export const HeaderButton = styled(Button)<{ border: 'left' | 'right' | 'both' }>`
  && {
    border-radius: 0;
    padding: 0 ${({ theme }) => theme.spacingComfortable}px;
    height: 100%;
    background: ${({ variant, theme }) =>
      variant === ButtonVariants.Priority
        ? setAlpha(0.06, theme.colors.gray['100'])
        : variant === ButtonVariants.Default
        ? 'transparent'
        : ''};
    &,
    &:hover,
    &:focus {
      ${({ border }) => (border === 'left' || border === 'both' ? `border-left: 1px solid var(--backgroundBody);` : '')}
      ${({ border }) =>
        border === 'right' || border === 'both' ? `border-right: 1px solid var(--backgroundBody);` : ''}
    }
    &:hover,
    &:focus {
      background: ${({ variant, theme }) =>
        variant === ButtonVariants.Priority
          ? setAlpha(0.08, theme.colors.gray['100'])
          : variant === ButtonVariants.Default
          ? setAlpha(0.04, theme.colors.gray['100'])
          : ''};
    }
  }
`;
