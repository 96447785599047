import type { ICellRendererParams } from 'ag-grid-enterprise';
import { isFunction } from 'lodash';
import { Button } from '../Button';
import type { AgGridButtonProps } from './types';

export function AgGridButton({
  onClick,
  node,
  api,
  columnApi,
  children,
  size,
  disabled,
  hide,
  ...props
}: Omit<ICellRendererParams, 'disabled'> & AgGridButtonProps) {
  if (hide && node.data && hide(node.data)) {
    return null;
  }
  return (
    <Button
      size={size}
      onClick={e => {
        e.stopPropagation();
        onClick({ node, api, columnApi });
      }}
      disabled={isFunction(disabled) ? disabled({ node, api, columnApi }) : disabled}
      {...props}
    >
      {children}
    </Button>
  );
}
