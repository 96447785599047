import { Box, ButtonVariants, FormControlSizes, HStack, Icon, IndicatorBadge, Tooltip } from '@talos/kyoko';
import { HeaderButton } from '../../../Header/styles';
import type { TradingControlsDialogProps } from './types';

export function TradingControlsDialogButton(props: TradingControlsDialogProps) {
  const { dialog, enabled, dialogInfo } = props;

  if (!enabled) {
    return null;
  }

  return (
    <Box background={dialogInfo?.backgroundColor}>
      <HeaderButton
        border="left"
        onClick={() => dialog.open()}
        size={FormControlSizes.Small}
        variant={ButtonVariants.Default}
        data-testid="header-button-trading-controls"
      >
        <Tooltip tooltip={dialogInfo?.tooltip} placement="bottom">
          <HStack gap="spacingSmall">
            {dialogInfo?.iconName && <Icon icon={dialogInfo.iconName} color={dialogInfo.iconColor} />}
            Trading Controls
            {dialogInfo?.counter && <IndicatorBadge>{dialogInfo.counter}</IndicatorBadge>}
          </HStack>
        </Tooltip>
      </HeaderButton>
    </Box>
  );
}
