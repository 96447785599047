import {
  AccordionGroup,
  BLOTTER_TABLE_FILTERS_CONTAINER_ID,
  Box,
  HStack,
  LookbackOption,
  usePortal,
  VStack,
} from '@talos/kyoko';
import { FilteredOrders } from './FilteredOrders';
import { ORDERS_BLOTTER_PREFIX } from './tokens';

const OrderBlotterSettings = {
  label: 'Recent',
  id: 'recent-orders-pms',
  closable: true,
  editable: true,
  defaultFilter: {
    _start: LookbackOption.Past24Hours,
  },
  defaultColumns: [],
};
/** Simplified version of the Orders components that excludes the Inner Tabs */
export const RecentOrders = () => {
  const { setPortalRef: filtersContainerRef } = usePortal(BLOTTER_TABLE_FILTERS_CONTAINER_ID);
  return (
    <VStack alignItems="stretch" gap="spacingTiny" h="100%" w="100%">
      <HStack h="40px" w="100%" justifyContent="right" gap="spacingComfortable" background="backgroundContent">
        <Box ref={filtersContainerRef} px="spacingDefault" />
      </HStack>{' '}
      <AccordionGroup key={OrderBlotterSettings.id}>
        <FilteredOrders
          key={OrderBlotterSettings.id}
          blotterID={`${ORDERS_BLOTTER_PREFIX}/${OrderBlotterSettings.id}`}
          tabLabel={OrderBlotterSettings.label}
          defaultFilter={[]}
          onCloneTab={() => {}}
          initialIsOpen={false}
        />
      </AccordionGroup>
    </VStack>
  );
};
