// API

export const AMEND = 'amend';
export const CANCEL = 'cancel';
export const SUBSCRIBE = 'subscribe';
export const PAGE = 'page';

// Publication types
export const ORDER_CANCEL_REQUEST = 'OrderCancelRequest';
export const ORDER_MODIFY_REQUEST = 'OrderCancelReplaceRequest';
export const ORDER_TICKET_TOP_OF_BOOK = 'ORDER_TICKET_TOP_OF_BOOK';
export const BALANCES_LIST = 'BALANCES_LIST';
export const ORDER_CONTROL_REQUEST = 'OrderControlRequest';

// Subscription type
export const ADDRESS = 'Address';
export const BALANCE = 'BalanceSnapshot';
export const BALANCE_DELTA = 'BalanceDelta';
export const BALANCE_TRANSACTION = 'BalanceTransaction';
export const CURRENCY = 'Currency';
export const CURRENCY_CONVERSION = 'CurrencyConversion';
export const CURRENT_FEE_TIER = 'CurrentFeeTier';
export const FUNDING_EVENT = 'FundingEvent';
export const EQUITY_MARGIN_RATIO_LIMIT = 'EquityMarginRatioLimit';
export const EXECUTION_REPORT = 'ExecutionReport';
export const EXPOSURE = 'Exposure';
export const EXPOSURE_DEFINITION = 'ExposureDefinition';
export const ERROR = 'error';
export const FEE_TIER = 'FeeTier';
export const HEDGE_POSITION_STATUS = 'HedgePositionStatus';
export const HEDGE_ORDER_STATUS = 'HedgeOrderStatus';
export const HISTORICAL_POSITION_STAT = 'HistoricalPositionStat';
export const HISTORICAL_POSITION_STAT_AS_OF = 'HistoricalPositionStatAsOf';
export const LOAN = 'Loan';
export const LOAN_QUOTE_BORROWER = 'LoanQuoteBorrower';
export const LOAN_QUOTE_LENDER = 'LoanQuoteLender';
export const LOAN_TRANSACTION = 'LoanTransaction';
export const MARKET = 'Market';
export const MARKET_ACCOUNT = 'MarketAccount';
export const MARKET_ACCOUNT_LEDGER_EVENT = 'MarketAccountLedgerEvent';
export const MARKET_ACCOUNT_PERMISSIONS_FILTER = 'MarketAccountPermissionFilter';
export const MARKET_DATA_CANDLESTICK = 'MarketDataCandlestick';
export const MARKET_DATA_SNAPSHOT = 'MarketDataSnapshot';
export const MARKET_DATA_STATISTICS = 'MarketDataStatistics';
export const MARKET_DATA_TRADE = 'MarketDataTrade';
export const MARKET_EXECUTION_REPORT = 'MarketExecutionReport';
export const MARKET_ORDER = 'MarketOrder';
export const MARKET_QUOTE = 'MarketQuote';
export const MARKET_SECURITY_STATUS = 'MarketSecurityStatus';
export const NEW_ORDER_SINGLE = 'NewOrderSingle';
export const NEW_TRANSFER = 'NewTransfer';
export const OHLCV = 'OHLCV';
export const ORDER = 'Order';
export const CARE_ORDER = 'CareOrder';
export const CARE_NEW_ORDER_SINGLE = 'CareNewOrderSingle';
export const CARE_EXECUTION_REPORT = 'CareExecutionReport';
export const ORDER_STRATEGY = 'OrderStrategy';
export const RECENT_TRADES = 'RecentTrades';
export const REFERENCE_RATE = 'ReferenceRate';
export const SECURITY = 'Security';
export const SPARKLINE = 'Sparkline';
export const SUB_ACCOUNT = 'Subaccount';
export const SUB_ACCOUNT_LEDGER_EVENT = 'SubAccountLedgerEvent';
export const SUB_ACCOUNT_ROLLUP = 'SubaccountRollup';
export const SUB_ACCOUNT_PERMISSION_FILTER = 'SubAccountPermissionFilter';
export const SUB_ACCOUNT_POSITION_LIMIT = 'SubAccountPositionLimit';
export const SUB_ACCOUNT_WINDOW_LIMIT = 'SubAccountWindowLimit';
export const TRADE = 'Trade';
export const TRADE_ALLOCATION = 'TradeAllocation';
export const TRADE_DIRECTION = 'TradeDirection';
export const TRADE_SETTLE_REPORT = 'TradeSettleReport';
export const TRANSFER = 'Transfer';
export const TRADING_LIMIT = 'TradingLimit';
export const POSITION = 'Position';
export const POSITION_SUB_ACCOUNT = 'SubAccountPosition';
export const RISK_SUB_ACCOUNT = 'SubAccountRisk';
export const PORTFOLIO_HISTORICAL_POSITION = 'PortfolioHistoricalPosition';
export const PORTFOLIO_HISTORICAL_POSITION_AS_OF = 'PortfolioHistoricalPositionAsOf';
export const PORTFOLIO_TREASURY_ACCOUNTS = 'PortfolioTreasuryAccounts';
export const PORTFOLIO_TRADING_ACCOUNTS = 'PortfolioTradingAccounts';
export const PORTFOLIO_TREASURY_LINKS = 'PortfolioTreasuryLinks';
export const PORTFOLIO_EXCHANGE_POSITIONS = 'PortfolioExchangePositions';
export const PORTFOLIO_OTC_POSITIONS = 'PortfolioOTCPositions';
export const PORTFOLIO_OTC_EXPOSURE = 'PortfolioOTCExposure';
export const PORTFOLIO_OTC_TREASURY = 'PortfolioOTCTreasury';
export const UNIFIED_LIQUIDITY_TOKEN = 'UnifiedLiquidityToken';
export const BUYING_POWER = 'BuyingPower';
export const CONFIGURATIONS = 'Configurations';
export const EXPOSURE_LIMIT = 'ExposureLimit';
export const USER = 'User';
export const USER_GROUP = 'UserGroup';
export const USER_GROUP_MEMBERSHIP = 'UserGroupMembership';
export const USER_SUB_ACCOUNT_PERMISSION_FILTER = 'UserSubAccountPermissionFilter';
export const TRANSFER_STREAM = 'TransferStream';
export const MAX_ORDER_SIZE_LIMIT = 'MaxOrderSizeLimit';
export const MARKET_ACCOUNT_CURRENCY = 'MarketAccountCurrency';
export const MARKET_EXPOSURE = 'MarketExposure';
export const RECON_STATUS = 'ReconStatus';
export const RECON_MISMATCH = 'ReconMismatch';
export const ROLLUP_MEMBERSHIP = 'RollupMembership';
export const TREASURY_ACCOUNT_LINK = 'TreasuryAccountLink';
export const CONNECTION_STATUS = 'ConnectionStatus';
export const FIXING_INDEX = 'Index';
export const MARKET_FIXING_INDEX = 'MarketIndex';

// MarketData type
export const PRICE = 'PRICE';
export const SIZE = 'SIZE';
export const TOP_OF_BOOK_SIZE = 'TOP_OF_BOOK_SIZE';

// CurrencyConversion tags
export const TRADING_LIMITS_CURRENCY_CONVERSION = 'TRADING_LIMITS_CURRENCY_CONVERSION';

// Heartbeats
export const AVA_PING = 'ping';
export const AVA_PONG = 'pong';
export const WL_PING = 'Ping';
export const WL_PONG = 'Pong';

// RFQ
export const QUOTE_REQUEST = 'QuoteRequest';
export const QUOTE = 'Quote';
export const QUOTE_HISTORY = 'QuoteHistory';
export const QUOTE_CANCEL_REQUEST = 'QuoteCancelRequest';

// Dealer
export const CUSTOMER = 'Customer';
export const CUSTOMER_ADDRESS = 'CustomerAddress';
export const CUSTOMER_BALANCE = 'CustomerBalance';
export const CUSTOMER_BALANCE_TRANSACTION = 'CustomerBalanceTransaction';
export const CUSTOMER_CREDIT = 'CustomerCredit';
export const CUSTOMER_QUOTE = 'CustomerQuote';
export const CUSTOMER_QUOTE_HISTORY = 'CustomerQuoteHistory';
export const CUSTOMER_TRADE = 'CustomerTrade';
export const CUSTOMER_ORDER = 'CustomerOrder';
export const CUSTOMER_ORDER_SUMMARY = 'CustomerOrderSummaryV2';
export const CUSTOMER_SECURITY = 'CustomerSecurity';
export const CUSTOMER_USER = 'CustomerUser';
export const CUSTOMER_ROLE = 'CustomerRole';
export const PRICING_RULE = 'PricingRule';
export const CUSTOMER_MAX_ORDER_SIZE_LIMIT = 'CustomerMaxOrderSizeLimit';
export const CUSTOMER_QUOTE_REQUEST = 'CustomerQuoteRequest';
export const CUSTOMER_QUOTE_CANCEL_REQUEST = 'CustomerQuoteCancelRequest';
export const CUSTOMER_NEW_ORDER_SINGLE = 'CustomerNewOrderSingle';
export const CUSTOMER_EXECUTION_REPORT = 'CustomerExecutionReport';
export const CUSTOMER_TRANSACTION = 'CustomerTransaction';
export const CUSTOMER_TRANSACTION_HISTORY = 'CustomerTransactionHistory';
export const CUSTOMER_QUOTE_UPDATE_REQUEST = 'CustomerQuoteUpdateRequest';
export const CUSTOMER_USER_API_KEY = 'CustomerUserApiKey';
export const CUSTOMER_EXECUTION_RULE = 'CustomerExecutionRule';
export const CUSTOMER_FIX_CONNECTION = 'CustomerFIXConnection';
export const CUSTOMER_MARKET_ACCOUNT = 'CustomerMarketAccount';
export const CUSTOMER_MARKET_DATA_SNAPSHOT = 'CustomerMarketDataSnapshot';
export const CUSTOMER_ORDER_MODIFY_REQUEST = 'CustomerOrderCancelReplaceRequest';
export const CUSTOMER_ORDER_CANCEL_REQUEST = 'CustomerOrderCancelRequest';
export const NEW_DEPOSIT_REQUEST = 'NewDepositRequest';
export const NEW_WITHDRAW_REQUEST = 'NewWithdrawRequest';
export const WITHDRAW_CANCEL_REQUEST = 'WithdrawCancelRequest';

// Analytics
export const ORDER_ANALYTIC = 'OrderAnalytic';

// On-Platform-Analytics
export const BID_ASK_SPREADS_DATAPOINTS = 'BidAskSpreadsDatapoints';
export const TOTAL_TRADING_VOLUME = 'TotalTradingVolume';
export const NUM_ORDERS_DATAPOINTS = 'NumOrdersDatapoints';
export const SLIPPAGE_DATAPOINTS = 'SlippageDatapoints';
export const ORDERS_SUMMARY_DATAPOINTS = 'OrdersSummaryDatapoints';
export const MARKET_ORDER_OUTCOME_DATAPOINTS = 'MarketOrderOutcomeDatapoints';
export const POST_TRADE_ORDER_ANALYTICS = 'PostTradeOrderAnalytics';
