import {
  Box,
  Drawer,
  HStack,
  ICON_SIZES,
  Icon,
  IconName,
  Tab,
  TabAppearance,
  TabButton,
  TabList,
  TabSize,
  Tabs,
} from '@talos/kyoko';
import { useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import { useAppwideDrawerContext } from '../../providers/AppwideDrawerProvider';

export const AppwideDrawer = () => {
  const { drawer, contentSpec } = useAppwideDrawerContext();

  // Whenever the user changes the spec, we guarantee a remount of the drawer content.
  // React / JSX is tricky when you are doing stuff like passing in a function to render a component across react life cycles.
  // Doing this just makes things simpler. There may very well be much better ways of handling this whole work flow.
  const contentSpecHash = useMemo(() => (contentSpec ? uuid() : undefined), [contentSpec]);

  return (
    <Drawer {...drawer} data-testid="app-wide-drawer">
      <HStack
        justifyContent="space-between"
        borderBottom="1px solid"
        borderColor="backgroundBody"
        background="backgroundFilledTabList"
      >
        <Tabs appearance={TabAppearance.Filled} size={TabSize.Small} selectedIndex={0}>
          <TabList>
            <Tab label={contentSpec?.title} />
          </TabList>
        </Tabs>
        <TabButton
          size={TabSize.Small}
          onClick={() => drawer.close()}
          suffix={<Icon icon={IconName.Clear} size={ICON_SIZES.SMALL} />}
          appearance={TabAppearance.Filled}
        />
      </HStack>

      <Box flex="1" key={contentSpecHash} style={{ overflowY: 'auto' }}>
        {contentSpec?.renderContent?.()}
      </Box>
    </Drawer>
  );
};
