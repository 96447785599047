import {
  AccordionGroup,
  BLOTTER_TABLE_FILTERS_CONTAINER_ID,
  Box,
  formattedDateForSubscription,
  HStack,
  LookbackOption,
  usePortal,
  VStack,
} from '@talos/kyoko';
import { subDays } from 'date-fns';
import type { TradeTabProps } from '.';
import { DEFAULT_TRADE_COLUMNS } from './columns';
import { FilteredTrades } from './FilteredTrades';
import { TRADES_BLOTTER_PREFIX } from './tokens';

const TradesBlotterSettings = {
  label: 'Recent',
  id: 'recent-trades-pms',
  closable: true,
  editable: true,
  defaultFilter: {
    _start: LookbackOption.Past24Hours,
  },
  defaultColumns: [],
};

const RECENT_TAB_DEFAULTS: TradeTabProps = {
  defaultFilter: {
    StartDate: formattedDateForSubscription(subDays(new Date(), 1)),
  },
  defaultColumns: DEFAULT_TRADE_COLUMNS,
};
/** Simplified version of the Trades components that excludes the Inner Tabs */
export const RecentTrades = () => {
  const { setPortalRef: filtersContainerRef } = usePortal(BLOTTER_TABLE_FILTERS_CONTAINER_ID);
  return (
    <VStack alignItems="stretch" gap="spacingTiny" h="100%" w="100%">
      <HStack h="40px" w="100%" justifyContent="right" gap="spacingComfortable" background="backgroundContent">
        <Box ref={filtersContainerRef} px="spacingDefault" />
      </HStack>{' '}
      <AccordionGroup key={TradesBlotterSettings.id}>
        <FilteredTrades
          key={TradesBlotterSettings.id}
          blotterPersistID={`${TRADES_BLOTTER_PREFIX}/${TradesBlotterSettings.id}`}
          tabLabel={TradesBlotterSettings.label}
          defaultColumns={RECENT_TAB_DEFAULTS.defaultColumns}
          defaultFilter={RECENT_TAB_DEFAULTS.defaultFilter}
          onCloneTab={() => ({})}
          initialIsOpen={false}
          showCreateManualTrade={true}
          showAPIOrdersToggle={true}
        />
      </AccordionGroup>
    </VStack>
  );
};
